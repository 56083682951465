import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faDribbble, faFacebookF, faTwitter, faBehance, faVimeoV, faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';
import { faCheck, faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  faDribbble = faDribbble;
  faFacebook = faFacebookF;
  faTwitter = faTwitter;
  faBehance = faBehance;
  faVimeo = faVimeoV;
  faGooglePlay = faGooglePlay;
  faApple = faApple;
  faStar = faStar;
  faStarHalf = faStarHalf;
  faCheck= faCheck;
  businessStories = [
    { 
      img: "assets/images/biz-success-placeholder.jpg",
      title: "Business Success Story Title",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamcorum.`
    },
    { img: "assets/images/biz-success-placeholder.jpg",
      title: "The Path to $350k/Year",
      content: `"One of our Top Tradies, Wayne Wockner, of Absolute Maintenance and Installation in Morayfield, 
        Queensland has been with ServiceSeeking.com.au for just 2 years, but his current turn-over sits at an almighty $350k/year"`
    },
    { 
      img: "assets/images/biz-success-placeholder.jpg",
      title: "Business Success Story Title",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamcorum.`
    },
    { 
      img: "assets/images/biz-success-placeholder.jpg",
      title: "Business Success Story Title",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamcorum.`
    },
    { 
      img: "assets/images/biz-success-placeholder.jpg",
      title: "Business Success Story Title",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamcorum.`
    },
    { 
      img: "assets/images/biz-success-placeholder.jpg",
      title: "Business Success Story Title",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamcorum.`
    },
    { 
      img: "assets/images/biz-success-placeholder.jpg",
      title: "Business Success Story Title",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamcorum.`
    },
    { 
      img: "assets/images/biz-success-placeholder.jpg",
      title: "Business Success Story Title",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamcorum.`
    },
    { 
      img: "assets/images/biz-success-placeholder.jpg",
      title: "Business Success Story Title",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamcorum.`
    }
  ];

  businessStoriesConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 3,
    "infinite": false,
    "autoplay": false,
    "dots": true,
    "dotsClass": "slick-dots d-none d-md-block",
    centerPadding: '40px',
    nextArrow: '<fa-icon class="icon-next-arrow ng-fa-icon"><svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg></fa-icon>',
    prevArrow: '<fa-icon class="icon-back-arrow ng-fa-icon"><svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg></fa-icon>',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ]
  };

  constructor( private router: Router,) { }

  ngOnInit() {
    localStorage.clear();
    //  this.router.navigateByUrl('');
  }

}
