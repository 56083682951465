<div style="width: 100%;float:left;">
    <app-main-header></app-main-header>
    <div class="contactPage" style="margin-left: 5%;margin-top: 8%;">

        <div  style="max-height :90vh;padding-bottom: 20px;">
            <div>
                <div class="popup-header">
                    <h3 class="mt-0 mb-0 pb-10 f-bold text-center text-black">Terms & Conditions</h3>
                </div>
                <div>
                    <div class="popup-container-s pt-20">
        
                        <div class="text">
                            These terms and conditions form the basis of your agreement with Tradie.com.au Pty Ltd ACN 631
                            458 449 (“Tradie). By clicking “Submit”, you agree to be bound by these Terms and Conditions.
        
                            <p>1. Definitions and interpretation
                                Unless the context otherwise requires, the following terms shall have the following meanings:
                            </p>
                            <ul>
                                <li>(a) “Consumers” means any party who accesses the Website in a capacity other than as a
                                    service
                                    provider.</li>
                                <li> (b) “Services” means the services and/or products provided by Tradie which include, but are
                                    not
                                    limited to, access to the Tradie platform and may include any additional services and/or
                                    products
                                    provided by Tradie from time to time.</li>
                                <li> (c) “Tradie” means Tradie.com.au Pty Ltd ACN 631 458 449 and includes our officers,
                                    directors,
                                    employees, agents, information providers, partners, advertisers, licensors and suppliers.
                                </li>
                                <li> (d) “Website” means Tradie.com.au.</li>
                                <li> (e) “You” means the person agreeing to these Terms and Conditions.</li>
                            </ul>
        
                        </div>
                        <div class="text">
                            <p> 2. Subscribing to the services</p>
                            <ul>
                                <li> (a) You acknowledge that by submitting your information, on launch of the Tradie.com.au
                                    website, your
                                    business information will be available for Consumers to contact you in relation to jobs or
                                    services.</li>
                                <li> (b) You agree to provide complete and accurate information on the sign up process,
                                    including your
                                    personal details to enable delivery of the Services. Tradie will not be liable to you or a
                                    third party
                                    for any loss for a delay or failure to process, fulfil or deliver the Services due to
                                    inaccurate or
                                    incomplete personal details.</li>
                                <li> (c) Where you are completing the sign up process on behalf of a business or company, you
                                    confirm that
                                    you are authorised to enter into such transactions.</li>
                                <li> (d) You agree to the use of electronic communication in order to enter into contracts,
                                    place orders and
                                    other records and to the electronic delivery of notices, policies and records of
                                    transactions initiated
                                    or completed with Tradie.</li>
                                <li> (e) You must not disclose your password to any third party and you are responsible for
                                    keeping it
                                    confidential. Tradie will never ask you to send your password or other sensitive information
                                    in an email
                                    or to enter it via any website other than one with an URL beginning with Tradie.com.au. If
                                    you suspect
                                    any unauthorised use of your service account or access to your password, please change your
                                    password
                                    immediately and contact Tradie. You are responsible for all unauthorised use of your
                                    services account by
                                    anyone who obtained access to your account directly or indirectly through you.</li>
                                <li> (f) Your account will remain open during the free trial period. Thereafter, you will be
                                    prompted to sign
                                    up for a paid membership if you choose.</li>
                                <li> (g) You may close your account at any time but you will remain liable for obligations
                                    related to your
                                    service account after it has been closed. The sections in these Terms and Conditions headed
                                    Copyright,
                                    Indemnity, Release and Limitation of Liability will continue to apply once your service
                                    account has been
                                    closed.</li>
                            </ul>
        
                        </div>
                        <div class="text">
                            <p> 3. Your relationship with Consumers</p>
                            <ul>
                                <li>(a) Tradie makes no warranty that Consumers will be appropriate for the services you
                                    provide, that
                                    Consumers will be solvent and pay invoices within due dates, or that Consumers will not
                                    cancel any
                                    services booked with you.</li>
                                <li>
                                    (b) You indemnify Tradie for any loss, damage, claim, liability or demand (howsoever
                                    arising, and
                                    whether at law, in equity, under statute or otherwise) arising out of the performance, or
                                    acceptance, by
                                    you of a job or services from a Consumer, or any breach of these terms by you.
                                </li>
                            </ul>
        
                        </div>
                        <div class="text">
                            <p> 4. Warranty and Warranty Claim</p>
                            <ul>
                                <li>
                                    (a) Where any law implies into these Terms and Conditions any term, condition or warranty
                                    and that
                                    law avoids or prohibits provisions in any contract excluding or modifying the application or
                                    exercise of
                                    or liability under such term, condition or warranty, such term, condition or warranty shall
                                    be deemed
                                    to be included in these Terms and Conditions.
                                </li>
                                <li>
                                    (b) Nothing in these Terms and Conditions purports to modify or exclude the conditions,
                                    warranties,
                                    guarantees and undertakings, and other legal rights, under the Australian Consumer Law and
                                    other
                                    laws
                                    which cannot be modified or excluded.
                                </li>
                                <div>
                                    (c) Except as provided in this clause:
                                    <ul>
                                        <li>
                                            (i) Tradie does not provide any warranties or guarantees as to the accuracy,
                                            completeness or
                                            suitability of any information the Website for any particular purpose;
                                        </li>
                                        <li>(ii) Tradie does not provide any warranties or guarantees that that any information
                                            or tools
                                            provided will be compliant with laws, legislation, rules or regulations.
                                        </li>
                                        <li>(iii) All terms, conditions, warranties, undertakings, inducements or
                                            representations whether
                                            expressed, implied, statutory or otherwise which are not expressly stated in these
                                            Terms and Conditions are
                                            expressly excluded; and</li>
                                        <li>
                                            (iv) Tradie will not be liable to you for any breach of these Terms and Conditions
                                            and Tradie will
                                            be under no liability to you in respect of any loss or damage (including any
                                            consequential loss or
                                            damage)howsoever caused which may be suffered or incurred or which may arise
                                            directly or indirectly in
                                            respect of the use of this website.
                                        </li>
                                    </ul>
        
                                </div>
        
                            </ul>
        
                        </div>
                        <div class="text">
                            <p>5. Indemnity</p>
                            <ul>
                                (a) You hereby indemnify Tradie against all claims, demands, remedies, suits, injury, damages,
                                loss,
                                costs, liabilities, actions, proceeding or right of action (including legal costs on an
                                indemnity
                                basis) arising from (but not limited to) your use of the website or any tools provided via the
                                Website
                                (including negligence) by you or any other person accessing the Website using your account.
                            </ul>
        
                        </div>
                        <div class="text">
                            <p>6. Limitation on Liability</p>
                            <ul>
                                <li>
                                    (a) Tradie will not be liable to you or any other person for an indirect, incidental,
                                    special or
                                    consequential damages whatsoever that you or any other person suffers or incurs, even if
                                    Tradie has
                                    been advised of the possibility of such damages or if they are foreseeable. The maximum
                                    aggregate
                                    liability that Tradie shall have to you shall not exceed the fees paid by for the Services.
                                    Any
                                    limitations on
                                    liability will survive termination of the Services.
                                </li>
                            </ul>
        
                        </div>
                        <div class="text">
                            <p>7. Release</p>
                            <ul>
                                <li>
                                    (a) You use the Website and any tools provided via the Website at your risk and to the
                                    extent
                                    permitted by law, release Tradie from all claims arising out of any damage or injury to any
                                    property
                                    or person
                                    occurring as a result of the use of the website and any tools provided via the Website.
                                </li>
                            </ul>
        
        
                        </div>
                        <div class="text">
                            <p> 8. Copyright</p>
                            <ul>
                                <li>
                                    (a) All copyright, data, text, software, images, graphics, trademarks, logos, interfaces,
                                    catalogues,brochures, flyers, photographs, and other intellectual property (“the Content”)
                                    on the Website or
                                    any other material of Tradie is owned, controlled by, or licensed to Tradie. You must not
                                    use, copy,
                                    modify, reproduce or distribute the Website or the Content without Tradie’s prior written
                                    consent. You must
                                    not frame or embed in another website any of the material appearing on this Website without
                                    Tradie’s
                                    prior written consent. You may view and print the Content for the sole purpose of using the
                                    Services in
                                    your business, but not for any other use, including any commercial use. You must not use any
                                    of the marks
                                    or trademarks appearing on this Website or Tradie’s name without Tradie’s prior written
                                    consent.
                                </li>
                                <li>
                                    (b) You warrant that all information or content you post and/or submit to be posted or used
                                    on the Website, including all advertisements, photos and creative designs, are either your
                                    own
                                    works or works which you are using with the permission of the owner. By submitting
                                    information or
                                    content (including advertisements, photos and designs) you automatically grant to Tradie, or
                                    warrant
                                    that the owner of such information has expressly granted to Tradie, a royalty-free,
                                    perpetual, irrevocable,
                                    worldwide non-exclusive license to use, reproduce, create derivative works from, modify,
                                    publish,
                                    edit, translate, distribute, disseminate, communicate, perform, and display your name and
                                    the
                                    information alone or as part of other works in any form, media, or technology whether now
                                    known or hereafter
                                    developed. You warrant that any and all information you post to the Website:
                                </li>
                                <ul>
                                    <li>
                                        (i) complies with all relevant laws;
                                    </li>
                                    <li>
                                        (ii) does not infringe the intellectual property rights (including but not limited to
                                        copyright and trade marks) of any person;
                                    </li>
                                    <li>
                                        (iii) is not misleading or deceptive nor likely to mislead or deceive; and
                                    </li>
                                    <li>
                                        (iv) does not violate any privacy laws or regulations or confidentiality restrictions.
                                        You warrant that any and all information submitted by you and posted on the
                                        hipages.com.au Web Site,
                                        including in any directories, is true, complete and correct.
                                    </li>
                                </ul>
                            </ul>
        
        
        
                        </div>
                        <div class="text">
                            <p>9. General</p>
                            <ul>
                                <li>
                                    (a) If any provision of these Terms and Conditions becomes void or unenforceable, it will be
                                    severed
                                    from this agreement without affecting the enforceability of the other provisions which will
                                    continue
                                    to have full force and effect.
                                </li>
                                <li>
                                    (b) These Terms and Conditions are governed by the laws of Queensland, Australia and each
                                    party
                                    irrevocably and unconditionally submits to the non-exclusive jurisdiction of the courts of
                                    Queensland, Australia.
                                </li>
                                <li> (c) Tradie reserves the right at all times to make changes to these Terms and Conditions.
                                    Any
                                    variations to these Terms and Conditions will take effect from posting on the Website.</li>
                                <li>
                                    (d) A failure or delay by Tradie to exercise a power or right under these Terms and
                                    Conditions does
                                    not constitute as a waiver of that power or right, and the exercise of a power or right by
                                    Tradie does
                                    not preclude its future exercise or the exercise of any power or right.
                                </li>
                            </ul>
        
                        </div>
                        <!-- <div [innerHTML]="result?.content">
                        </div> -->
                    </div>
                </div>
           
               
        
            </div>
           
        </div>
        

        </div>

 
    <app-footer-component></app-footer-component>
</div>