import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft , faChevronRight } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'landing-tradie-slider-component',
  templateUrl: './landing-tradie-slider.component.html',
  styleUrls: ['./landing-tradie-slider.component.scss']
})

export class LandingTradieSliderComponent implements OnInit {
  faStar = faStar;
  faStarHalf = faStarHalf;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight
  router: string;

  tradiesSlides = [
    {
      img: "assets/images/what-tradie-user-placeholder.png",
      name: "Bob, The Builder",
      address: "Metro Melbourne, VIC",
      stars: 5,
      halfStar: false,
      content: `“Insert info about tradesperson and  services they provide, regions they cover and their qualifications, experiences, etc.  
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non leo erat. Curabitur ut mollis lacus. 
          Fusce egestas orci in suscipit pulvinar. Cras vehicula semper purus.”`
    },
    {
      img: "assets/images/what-tradie-user-placeholder.png",
      name: "Bob, The Builder 2",
      address: "Metro Melbourne, VIC",
      stars: 3,
      halfStar: true,
      content: `“Insert info about tradesperson and  services they provide, regions they cover and their qualifications, experiences, etc.  
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non leo erat. Curabitur ut mollis lacus. 
          Fusce egestas orci in suscipit pulvinar. Cras vehicula semper purus.”`
    },
    {
      img: "assets/images/what-tradie-user-placeholder.png",
      name: "Bob, The Builder 3",
      address: "Metro Melbourne, VIC",
      stars: 4,
      halfStar: true,
      content: `“Insert info about tradesperson and  services they provide, regions they cover and their qualifications, experiences, etc.  
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non leo erat. Curabitur ut mollis lacus. 
          Fusce egestas orci in suscipit pulvinar. Cras vehicula semper purus.”`
    }
  ];

  tradieSlidesConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": true,
    "autoplay": false,
    centerPadding: '40px',
    nextArrow: '<div class="icon-next-arrow text-uppercase">  >  </div>',
    prevArrow: '<div class="icon-back-arrow text-uppercase"><</div>'
    // nextArrow: '<div class="icon-next-arrow text-uppercase">  <fa-icon [icon]="faChevronLeft"></fa-icon></div>',
    // prevArrow: '<div class="icon-back-arrow text-uppercase"> <fa-icon [icon]="faChevronRight"></fa-icon></div>'
  };

  /**
  * Creates an instance of documenter.
  */
  constructor(
    private _router: Router
  ) {
    this.router = _router.url;
  }

  ngOnInit() {
  }

}
