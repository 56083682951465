<div style="width: 100%;float:left;">
    <app-main-header></app-main-header>
    <div class="contactPage" style="margin-left: 5%;margin-top: 8%;">

        <div>
            <div class="popup-header">
                <h3 class="mt-0 mb-0 pb-10 f-bold text-center text-black">Privacy Policy</h3>
                <!-- <h3 class="mt-0 mb-0 pb-10 f-bold text-center text-black">{{result?.name}}</h3> -->
            </div>
            <div>
                <div class="popup-container-s pt-20">
                   
                    <div class="text">
                        Advanced Safety Systems Australia Pty Ltd ACN 096 796 137 (“ASSA”) recognises the importance of
                        privacy
                        and is committed to safeguarding the personal information that we hold. This policy describes how we
                        collect, store and manage personal information. This Privacy Policy does not apply to acts and
                        practices
                        which relate directly to the records of current and former employees that we hold.
                        Collecting personal information:
                    </div>
                    <ul>
                       <p>We collect personal information for reasons that include:</p>
                        <li>Communicating with you;</li>
                        <li>Enabling us to provide services to you;</li>
                        <li>Responding to your request for information about services;</li>
                        <li>Sending statements and invoices to you;</li>
                        <li>Collecting payments from you;</li>
                        <li>Marketing our services to you;</li>
                        <li>For our internal planning, promotional, product development, quality control and research
                            purposes;</li>
                        <li>Dealing with complaints, consumer guarantee and warranty claims;</li>
                        <li>Developing, managing, and administering our services;</li>
                        <li>Complying with our legal obligations.</li>
                    </ul>
    
                    <div class="text">This personal information may be obtained from you when you submit the information
                        online via our
                        website, by telephone or by correspondence. We may also collect information from third parties and
                        public sources.</div>
                    <div class="text">
                        The type of personal information we collect includes your name, address, telephone numbers, e-mail
                        address, payment information, transaction information, bank account details, credit card details,
                        Internet Protocol (“IP”) address, server address, domain name, information about your browsing
                        activity,
                        information contained on identification documents and other details that may be required to conduct
                        our
                        operations. We will never collect sensitive information.
                    </div>
                    <div>
                        <p>Using and disclosing your personal information:</p>
                        <div class="text"> We may use and disclose personal information for the primary purpose for which it
                            was collected. We may
                            also use or disclose personal information for reasonably expected secondary purposes which are
                            related
                            to the primary purpose and in other circumstances authorised by law.</div>
                        <div class="text"> We may disclose your personal information to our suppliers, agents or contractors
                            or anyone to whom you
                            authorise us to disclose it to.</div>
                        <div class="text"> We may disclose your personal information to third party service providers who
                            assist us in providing
                            goods and services or performing functions.</div>
                        <div class="text"> We may disclose your personal information to the extent that we are required to
                            do so by law or
                            connection with any legal proceedings or prospective legal proceedings involving us.</div>
                        <div class="text"> We may disclose your personal information to overseas contractors to enable us to
                            operate or maintain
                            our online and electronic platforms. Otherwise, we will only disclose your personal information
                            to an
                            overseas recipient when requested to do so by you.</div>
                    </div>
                    <div class="text">
                        <p>Access to your personal information:</p>
                        You may seek access to the personal information we hold about you. We may restrict access where we are
                        required or permitted to do so by law.
                    </div>
                    
                    <div class="text">
                       <p>Accuracy, completeness and up-to-date information:</p> 
                        We will take reasonable steps to ensure that the personal information we hold is accurate, complete and
                        up-to-date. You may contact us to update your personal information or tell us that the personal
                        information we hold about you is inaccurate, incomplete or out-of-date.
                    </div>
                 
                    <div class="text">
                        <p>Security:</p>
                        We will take reasonable steps to protect any personal information that we hold from misuse and loss. We
                        also take reasonable steps to protect it from unauthorised access, modification and disclosure. When we
                        no longer require your personal information, we will take reasonable steps to destroy, delete or
                        de-identify it.
                    </div>
                   
                    <div class="text">
                        <p>How to contact us:</p>
                        If you wish to gain access to your personal information or make a complaint about a breach of your
                        privacy or if you have any query on how your personal information is collected or used or any other
                        query relating to our Privacy Policy, please contact us via email at privacy@assa.com.au
                    </div>
    
    
                    <div class="text">
                        <p>Changes to our Privacy Policy:</p>
                        We reserve the right to make changes to our Privacy Policy at any time. We may update this Privacy
                        Policy by posting an updated version on our website https://www.assa.com.au
                    </div>
    
    
    
                    Updated 26 May 2020
    
                    <!-- <div [innerHTML]="result?.content">
                    </div> -->
                </div>
            </div>
        </div>

        </div>

 
    <app-footer-component></app-footer-component>
</div>