import { Component, OnInit } from '@angular/core';


import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faDribbble, faFacebookF, faTwitter, faBehance, faVimeoV, faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';

import { PopularCategories } from './PopularCategories';
import { AllCategories } from './AllCategories';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss']
})
export class DirectoryComponent implements OnInit {
  constructor() { }
  
  PopularCategories = PopularCategories;
  AllCategories = AllCategories;
  
  /** Imported FontAwesome Icons */
  faPaperPlane = faPaperPlane;
  faDribbble = faDribbble;
  faFacebook = faFacebookF;
  faTwitter = faTwitter;
  faBehance = faBehance;
  faVimeo = faVimeoV;
  faGooglePlay = faGooglePlay;
  faApple = faApple;
  ngOnInit() {
    
  }

}
