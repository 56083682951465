import { Component, OnInit } from '@angular/core';

import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faDribbble, faFacebookF, faTwitter, faBehance, faVimeoV, faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-directory-component',
  templateUrl: './directory-component.component.html',
  styleUrls: ['./directory-component.component.scss']
})
export class DirectoryComponentComponent implements OnInit {

  constructor() { }
  
  /** Imported FontAwesome Icons */
  faPaperPlane = faPaperPlane;
  faDribbble = faDribbble;
  faFacebook = faFacebookF;
  faTwitter = faTwitter;
  faBehance = faBehance;
  faVimeo = faVimeoV;
  faGooglePlay = faGooglePlay;
  faApple = faApple;

  ngOnInit() {
  }

}
