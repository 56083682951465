import { Component, OnInit } from '@angular/core';
import { CreateJobComponent } from '../shared/create-job/create-job.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faDribbble,faYoutube, faFacebookF, faTwitter, faBehance, faVimeoV, faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';
@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {
/** Imported FontAwesome Icons */
faPaperPlane = faPaperPlane;
faDribbble = faDribbble;
faYoutube = faYoutube ;
faFacebook = faFacebookF;
faTwitter = faTwitter;
faBehance = faBehance;
faVimeo = faVimeoV;
faGooglePlay = faGooglePlay;
faApple = faApple;
  constructor(
    public dialog: MatDialog,
    private router: Router
    
  ) { }

  ngOnInit() {
  }
  openCreateJob() {
    this.dialog.open(CreateJobComponent);
  }
}
