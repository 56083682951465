import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { DirectoryComponent } from './directory-component/directory/directory.component';
import { SearchCategoryComponent } from './directory-component/search-category/search-category.component';
import { DirectoryComponentComponent } from './directory-component/directory-component.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import {TermsConditionsComponent} from './terms-conditions/terms-conditions.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import { FaqComponent } from './faq/faq.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { LandingComponent } from './landing/landing.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./shared-component/shared-component.module').then(m => m.SharedComponentModule)
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
  },
  {
    path: 'tradie',
    loadChildren: () => import('./tradie/tradie.module').then(m => m.TradieModule)
  },
  {
    path: 'directory',
    component: DirectoryComponentComponent,
    children: [
      {
        path: '',
        component: DirectoryComponent
      },
      {
        path: 'browse',
        component: SearchCategoryComponent
      }
    ],
  },
  {
    path: 'contact_us',
    component : ContactUsComponent
  },
  {
    path: 'faq',
    component : FaqComponent
  },
  {
    path: 'about-us',
    component : AboutUsComponent
  },
  {
    path: 'pricing',
    component : LandingComponent
  },
  {
    path: 'terms-conditions',
    component : TermsConditionsComponent
  }
  ,{
    path:'privacy-policy',
    component : PrivacyPolicyComponent
  }
 
 

];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload', 
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
