<header class="main_header">
    
    <div class="container-fluid tradie-header">
        <div class="flex px-15 flex-sb flex-middle flex-sb">
            <a [routerLink]="['/']" class="main-logo"><img src='assets/images/logo.svg' alt='Logo'></a>
            <div class="flex flex-middle">
                <div class="burger-menu" role="menu">
                    <div class="burger"></div>
                </div>
                <nav class="tradie-menu main-menu">
                    <ul class="list-unstyled flex flex-middle flex-column-m menu-item py-3 py-lg-0">
                        <li class="mr-lg-4"><a (click)="openCreateJob()" class=" cursor-pointer">Get a Quote</a></li>
                        <!-- <li class="mr-lg-4 active"><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/directory']" class="">Directory</a>
                        </li> -->
                        <!-- <li class="mr-lg-4"><a href="#" class="">Cost Guide</a></li> -->
                       
                        <li class="mr-lg-4"><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"[routerLink]="['/pricing']" class="">List Your Business</a></li>
                        
                        <li class="mr-lg-4"><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/about-us']" class="">About Us</a>
                        </li>
                        <li class="active mr-lg-4 login">
                            <a class="loginBtn" [routerLink]="['/client/login']">
                                <span class="loginImg"><img
                                        src="../../assets/images/latest_images/loginBtn_img.svg" /></span>
                                <span class="text">
                                    Login
                                </span></a>
                        </li>
                        <li class="text-center"><button [routerLink]="['/client/sign-up']" style="width: 100px;"
                                class=" btn btn-primary btn-green"><span class="loginImg"><img
                                        src="../../assets/images/latest_images/user_white.svg" /></span>
                                <span class="text">
                                    Signup
                                </span></button></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</header>