const development = {
  production: false,
  AesSecretKey: '08277A08B0ABA70703E28A5FCED7396D',
  AesSecretIVKey: 'D9062EA86462F77E',
  baseUrl: '496A1C1E7B6DF8BD62A5D13F9CF3F8AA7BF26FD867EFB37DE01E716193F7E6EED2ED1FFBF457F59DEDECE2BE88708652',
  // Local
  // baseUrl: 'EA9EF80DFDDA0005ECE41D1F4F5B5DFF5C50090A82C41DE355F6104D0CBEC066',
  // baseUserUrl: 'EA9EF80DFDDA0005ECE41D1F4F5B5DFF5C50090A82C41DE355F6104D0CBEC066',
  // baseJobUrl: 'EA9EF80DFDDA0005ECE41D1F4F5B5DFFDA46E69A25557A1A0119F14A667043FF',
  // basePaymentUrl: 'EA9EF80DFDDA0005ECE41D1F4F5B5DFFE15DAC9049998BD08AED94DD00966C19',
  // Server
  baseUserUrl: '496A1C1E7B6DF8BD62A5D13F9CF3F8AA7BF26FD867EFB37DE01E716193F7E6EED2ED1FFBF457F59DEDECE2BE88708652',
  baseJobUrl: '8FFBC7DF46DEE313166FEFD5DDBC80735E357075D1879B02656C11518AF90B4305BBD42B5DA433358C9C7326B996C1F90C1849772EFD4D2F97FDE4D7C3D18C91BC0F4FEB1B0516D692DCB18C91E0A8BE',
  basePaymentUrl: '2A6775C727C97AEE7B66AFB4D8D714F5B0D837AD7EE6F5AEBED8BA04ED4307ACC80DD6634F7BED97EAB8BC9DA58FEEEADF9076B5D669000970FC1BACA496242367DFAF02EC68F42B0C6777B58E5E24CA',
  ClientSecret: '5c3d46a7c0d6dc57a9d817a1dec383b027c5c0ef476084ec9264842790ee271b',
  socketURL: 'D3683A75A313A1135CF6DD27581DA3D674FBE07E31AEC58BEF56AE7C53AD1A4D',
  chatUrl: 'wss://0x18b0yjfe.execute-api.ap-southeast-2.amazonaws.com/dev',
  googleApiKey: 'C928EE87FA75381F2D48F7EDF537378EA633763AF68CA9FE33566964D2BAE166DA241A52863D8553FEF6EE67BBC6C3194DD6D43E1220E02D121AA3D536A1194FF9F4FE1793DA98333CE68EBA618D727A',
  facebookApiKey: '',
  stripeSetupExpressAccountUrl: 'D19923E23ED314B5F02261D0CCBA55BEC884B520D130A25DE8053A7EFE0FADDD41A7181EDF7EA6EC7E01043ADE06F6AC08F962F620D6EA630D52F5617F46EBF4FC45D823F26162485FC429E944C849BF94C1F12D40FAB037CCCA9ACC5771FEEB5C1D92355E2A6124186BF44D18D166536364242EE5BC2E08D0C9A585A054B3AD7879A1D5BD611921153B0EBD3880718D',
  stripePublishableKey: "pk_test_51IlpcQHN6c7xXTsEZNQVWRAiG3o3ovhwucYlag2mQUrLLH7LKVy5yhgwwGHtnHiHcXQncbZnAPZDsMbPmQI6Ghmm00Ei9P1wDU"
}
const staging = {
  production: false,
  AesSecretKey: '08277A08B0ABA70703E28A5FCED7396D',
  AesSecretIVKey: 'D9062EA86462F77E',
  baseUrl: '582db7e567ab0e73452b72b8c7e7674654d0672729e5749dfcde3102fe1d34adda3dcdfc22117e1c56c373fbd900796b160fba47f74b1587c01f62b4d26935505fb45acd71b013c65499578f7cf8b402',
  baseUserUrl: '582db7e567ab0e73452b72b8c7e7674654d0672729e5749dfcde3102fe1d34adda3dcdfc22117e1c56c373fbd900796b160fba47f74b1587c01f62b4d26935505fb45acd71b013c65499578f7cf8b402',
  baseJobUrl: '53381dc7bd052beac0e2e2d141ed43b8b062ac0ffb395286cd93ca8f20ae32edfb0b856bb4afc2de41f9bde20e78daebb4289c899fe9d9065a75078c1b01fec1dd27274b135871aafbf260296645eca7',
  // basePaymentUrl: '5747fb49a968978713d0b55ca265ff7b1cc8a84bacd903a8353533c6c99bbb19ca58e448d2a1689136f1ad6eca007b4dbd2c4382e116010f9decc1a47246deea0405fb0e157442dfa38ccc82e25341b7',
  basePaymentUrl: '1eb37cae7e991345afae31ed23c211ee18042f595a0b1a3f074d1e53d2061056a9331f70447d710b4269eabd1aca3b58df4e8991f4fb55928d875c0fcca850230d9e9179d86f1b7cfd8fa724ed353b23',
  ClientSecret: '5c3d46a7c0d6dc57a9d817a1dec383b027c5c0ef476084ec9264842790ee271b',
  socketURL: 'D3683A75A313A1135CF6DD27581DA3D674FBE07E31AEC58BEF56AE7C53AD1A4D',
  chatUrl: 'wss://fb48aimii7.execute-api.ap-southeast-2.amazonaws.com/staging',
  googleApiKey: 'C928EE87FA75381F2D48F7EDF537378EA633763AF68CA9FE33566964D2BAE166DA241A52863D8553FEF6EE67BBC6C3194DD6D43E1220E02D121AA3D536A1194FF9F4FE1793DA98333CE68EBA618D727A',
  facebookApiKey: '',
  stripeSetupExpressAccountUrl: 'D19923E23ED314B5F02261D0CCBA55BEC884B520D130A25DE8053A7EFE0FADDD41A7181EDF7EA6EC7E01043ADE06F6AC08F962F620D6EA630D52F5617F46EBF4FC45D823F26162485FC429E944C849BFFEF9E62E22834E2BB964CB5966AFF403E8E15F1ECB09E1FF0BB795531FE2B8EBD5C73A92D8A9C581019CB02D141563FCC84E5E9AB5F8BF921C91E3DB46B0BF25',
  stripePublishableKey: "pk_test_51HuXDLFk7XrLsX4qjWmWNGfnJhFRvBslJzSgzMhksw8nvsYNGFGm0NQLt4O1crSwrDV7ohEO8KXgZI7QcmtZMAYS008DrqFcUn"
}
const production = {
  production: false,
  AesSecretKey: '08277A08B0ABA70703E28A5FCED7396D',
  AesSecretIVKey: 'D9062EA86462F77E',
  baseUrl: '01919a83cc7c33d83d66a170cca526686922d72004609306510cc9eeb15d1bdcc959dff635c1e44364780c42b98783ffe83c03c328ac132f916f3702c971145ea7ee40f848f67222a0c576ec1154d4a0',
  baseUserUrl: '01919a83cc7c33d83d66a170cca526686922d72004609306510cc9eeb15d1bdcc959dff635c1e44364780c42b98783ffe83c03c328ac132f916f3702c971145ea7ee40f848f67222a0c576ec1154d4a0',
  baseJobUrl: '12ebc398a664458a9a0d4aed46538cae3446f88121800252b557d0be4ffaa5b87b52921ae4474222e3ada93bd6297667ea9a71783c362b7b6700428047df27009322a30b6a5ead39f0993814a3086188',
  basePaymentUrl: '1edb8818df72bb497041e349aa6a2540a96d8e1457e3f8c7b0fded7d625612c6438d5aa8922a4fab26c301602354fc77205615c44e78213b4304b2ff68f31ed147609d85dbebbc47e77001e6f2dfa44e',
  ClientSecret: '5c3d46a7c0d6dc57a9d817a1dec383b027c5c0ef476084ec9264842790ee271b',
  // socketURL: 'D3683A75A313A1135CF6DD27581DA3D674FBE07E31AEC58BEF56AE7C53AD1A4D',
  chatUrl: 'wss://n51m6qpfgi.execute-api.ap-southeast-2.amazonaws.com/prod',
  googleApiKey: 'C928EE87FA75381F2D48F7EDF537378EA633763AF68CA9FE33566964D2BAE166DA241A52863D8553FEF6EE67BBC6C3194DD6D43E1220E02D121AA3D536A1194FF9F4FE1793DA98333CE68EBA618D727A',
  facebookApiKey: '',
  stripeSetupExpressAccountUrl: 'D19923E23ED314B5F02261D0CCBA55BEC884B520D130A25DE8053A7EFE0FADDD41A7181EDF7EA6EC7E01043ADE06F6AC08F962F620D6EA630D52F5617F46EBF4FC45D823F26162485FC429E944C849BF8AFCC5DD6CD9ACD6606D8F7256B1815F2262D75DC50441AE13227B2B5604426E7987C853F4952A207FEE3C9643C1DCE8C63B005B6813F86E6E11E1A228987619',
  stripePublishableKey: "pk_live_51HuXDLFk7XrLsX4qHGk8LfSmClIieaXkRHMZaLxdVN2SCeNXayESkLZ3UxHQRUKfQ8aRq1He4zzblB0SiDoQ8gHv00Xb5b4coF"
}
export { development, staging, production }
