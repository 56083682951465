import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatDialogModule,
  MatIconModule,
  MatMenuModule,
  MatTooltipModule,
  MatBadgeModule
} from '@angular/material';

const MaterialModuleComponent = [
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatPaginatorModule,
  MatDialogModule,
  MatIconModule,
  MatMenuModule,
  MatTooltipModule,
  MatBadgeModule
]

@NgModule({
  imports: [MaterialModuleComponent],
  exports: [MaterialModuleComponent]
})
export class MaterialModule { }
