import { Component, OnInit, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EncryptionService } from 'src/app/services/encryption-service/encryption.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-category',
  templateUrl: './search-category.component.html',
  styleUrls: ['./search-category.component.scss']
})
export class SearchCategoryComponent implements OnInit {
  baseJobUrl : string;
  baseUserUrl : string;
  tradie_data: any[];
  category_list:any[];
  category_input: string = '';
  
  headers = {'access-token': localStorage.getItem('accessToken')};
  
  constructor(private http: HttpClient,
   private encryptionService : EncryptionService ) {
    this.baseJobUrl = this.encryptionService.decryptUsingAES256(environment.baseJobUrl);
    this.baseUserUrl = this.encryptionService.decryptUsingAES256(environment.baseUserUrl);
   }
  
  ngOnInit() {
    const headers = this.headers;
    this.http.get<any>( this.baseUserUrl + 'tradie-search?page=1&itemsPerPage=1000&categoryId=1&regionId=9', {headers}).subscribe(response => {
      this.tradie_data = response.data;
    });
    this.getCategoryList();
    
    console.log(headers);
  }
  
  /*
  Get list of category to be displayed
  on the category input textbox
  */
  getCategoryList(){
    const headers = this.headers;
    this.http.get<any>(this.baseJobUrl + 'opportunity-categories', {headers}).subscribe(response => {
      this.category_list = response.data;
      console.log(this.category_list)
    });
  }
  
  search(category,region){
    const headers = this.headers;
    if(category === "")category = 1;
    if(region === "")region = 1;
    category = this.category_list.find(item => item.categoryName === category).opportunityCategoryId;
    
    this.http.get<any>( this.baseUserUrl + 'tradie-search?page=1&itemsPerPage=1000&categoryId='+ category+'&regionId='+region, {headers}).subscribe(response => {
      this.tradie_data = response.data;
    });
    console.log();
    this.getCategoryList();
  }
}
