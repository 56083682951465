import { Injectable } from '@angular/core';
import { EncryptionService } from '../encryption-service/encryption.service';
import { environment } from "src/environments/environment";
import { DispatcherService } from 'src/app/service-dispatcher/dispatcher.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  baseJobUrl: string;
  baseUserUrl: string;
  headers: any;
  category_list: any[];
  
  constructor(
    private dispatcher: DispatcherService,
    private encryptionService: EncryptionService,
    private http: HttpClient
  ) {
    this.baseJobUrl = this.encryptionService.decryptUsingAES256(environment.baseJobUrl);
    this.baseUserUrl = this.encryptionService.decryptUsingAES256(environment.baseUserUrl);
  }
  
  /**
  * Method: searchTradie
  * Purpose : get Tradies Search List
  * @param params The parameter for this method
  */
  search(params){
    return new Promise((resolve, reject) => {
      this.dispatcher
        .get(this.baseUserUrl + '/tradie-search?page='+params.page+'&itemsPerPage='+params.itemsPerPage+'&categoryId='+ params.category+'&regionId='+params.region)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  
  /**
  * Method: getCategoryList
  * Purpose : Get list of category to be displayed on the data list
  * @param params The parameter for this method
  */
   getCategoryList(){
    return new Promise((resolve, reject) => {
      this.dispatcher
        .get(this.baseJobUrl + "opportunity-categories")
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
   }
   
   /**
  * Method: getPostCode
  * Purpose : Get Postcodes
  * @param params The parameter for this method
  */
    getPostCode(){
      return new Promise((resolve, reject) => {
        this.dispatcher
          .get(this.baseJobUrl + "opportunity-postcode")
          .subscribe(
            (res: any) => {
              resolve(res);
            },
            err => {
              reject(err);
            }
          );
      });
     }
}
