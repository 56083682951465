import { Injectable, Injector } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../../../environments/environment';
import { LoaderService } from '../../loader/loader.service';
import { EncryptionService } from '../encryption-service/encryption.service';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  config: any = {};
  constructor(
    private http: HttpClient,
    private inject: Injector,
    private encryptionService: EncryptionService
  ) { }
  public init() {
    return new Promise((resolve, reject) => {
      this.inject.get(LoaderService).show();
      resolve(true);
      this.inject.get(LoaderService).hide();
      // this.getS3Url().then((res) => {
      //   const url: any = res;
      //   return from(
      //     fetch(url).then((response) => {
      //       return response.json();
      //     })
      //   ).pipe(
      //     map((config) => {
      //       window.config = config;
      //       window.config.base_url_server = window.config.base_url_server + '/';
      //       window.config.base_url_local = window.config.base_url_local + '/';
      //       this.config = config;
      //       this.inject.get(LoaderService).hide();
      //       resolve(true);
      //       return;
      //     })).toPromise();
      // }).catch((err) => {
      //   resolve(true);
      //   this.inject.get(LoaderService).hide();
      // });
    });
  }

  // private getS3Url() {
  //   const bucket = new S3(
  //     {
  //       accessKeyId: this.encryptionService.decryptUsingAES256(environment.awsCredential.accessKeyId),
  //       secretAccessKey: this.encryptionService.decryptUsingAES256(environment.awsCredential.secretAccessKey),
  //       region: this.encryptionService.decryptUsingAES256(environment.awsCredential.region)
  //     }
  //   );

  //   const params = {
  //     Bucket: this.encryptionService.decryptUsingAES256(environment.awsCredential.Bucket),
  //     // Key: environment.awsCredential.Key,
  //     Expires: environment.awsCredential.Expires
  //   };
  //   return new Promise((resolve, reject) => {
  //     bucket.getSignedUrl('getObject', params, (err, url) => {
  //       if (err) {
  //         reject(err);
  //       } else {
  //         resolve(url);
  //       }
  //     });
  //   });
  // }

  getConfig() {
    return this.config;
  }

}