import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DispatcherService } from 'src/app/service-dispatcher/dispatcher.service';
import { EncryptionService } from '../../encryption-service/encryption.service';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  baseUserUrl: string;
  envConfig = window.config;

  constructor(
    private dispatcher: DispatcherService,
    private encryptionService: EncryptionService,
  ) {
    this.baseUserUrl = this.encryptionService.decryptUsingAES256(environment.baseUserUrl);
  }


  /**
  * Method: userProfile
  * Purpose : Get user profile
  * @param params The parameter for this method 
  */
  userProfile(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseUserUrl + 'clients/' + params, '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: userEditProfile
  * Purpose : Update user profile
  * @param params The parameter for this method 
  */
  userEditProfile(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.put(this.baseUserUrl + `clients/${this.encryptionService.decryptUsingAES256(localStorage.getItem('clientId'))}`, params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
   * Method: fileUpload
   * Purpose : Upload File
   */
  fileUpload(uploadData) {
   return new Promise((resolve, reject) => {
     this.dispatcher.post(this.baseUserUrl + `file-upload`, uploadData).subscribe((res: any) => {
       resolve(res);
     }, err => {
       reject(err);
     });
   })
  }
  
  /**
   * Method: fileUpload
   * Purpose : Upload File
   */
  fileDelete(deleteFileData) {
   return new Promise((resolve, reject) => {
     this.dispatcher.delete(this.baseUserUrl + `file-delete`, deleteFileData).subscribe((res: any) => {
       resolve(res);
     }, err => {
       reject(err);
     });
   })
  }
}
