<app-main-header></app-main-header>

<div class="relative tradie-banner">
    <div class="banner-wrapper">
        <div class="col-12 col-lg-6">

        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-start flex-column"
            style="margin-top: 100px;">

            <div class="container h-100">
                <div class="row h-100 pb-5 pb-lg-0">
                    <h4 class="text-uppercase text-left font-weight-bold"
                        style="color: #fff;font-size: 40px;margin-bottom: 10px;">Let’s Do This.</h4>
                    <p>Create your dream pipeline, become more profitable and cut <br>
                        your admin in half. Our system is purpose-built to turn your <br>
                        trade skills into a thriving business.</p>

                </div>
            </div>
        </div>
    </div>
</div>


<div class="subscps col-lg-12">
    <div class="subcards col-md-4">
        <div class="sub-header">
            <h5>Basic</h5>
        </div>
        <div class="plan">
            <h3 class="font-weight-bold">$19<span>/Week</span></h3>
        </div>
        <div class="sub-list">
            <ul>
                <li>
                    <span class="icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="text">First 30 days FREE - cancel anytime!</span>
                </li>
                <li>
                    <span class="icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="text">Access jobs in choice of 3 x regions</span>
                </li>
                <li>
                    <span class="icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="text">Quote, book and manage jobs</span>
                </li>
                <li>
                    <span class="icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="text">Accept secure payment</span>
                </li>
                <li>
                    <span class="icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="text">Support from Tradie team</span>
                </li>
            </ul>
        </div>
        <div class="btton text-center">
            <button type="button" [routerLink]="['/tradie/sign-up']" class="btn btn-primary btn-green">Get Started</button>
        </div>
        <p class="content">Need more regions? Check out our PREMIUM and PRO plans. You can also add single regions or
            upgrade to unlimited regions within your account at any time.</p>
    </div>
    <div class="subcards col-md-4">
        <div class="sub-header">
            <h5>Premium</h5>
        </div>
        <div class="plan">
            <h3 class="font-weight-bold">$35<span>/Week</span></h3>
        </div>
        <div class="sub-list">
            <ul>
                <li>
                    <span class="icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="text">First 30 days FREE - cancel anytime!</span>
                </li>
                <li>
                    <span class="icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="text">Access jobs in choice of 5 x regions</span>
                </li>
                <li>
                    <span class="icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="text">Quote, book and manage jobs</span>
                </li>
                <li>
                    <span class="icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="text">Accept secure payment</span>
                </li>
                <li>
                    <span class="icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="text">Support from Tradie team</span>
                </li>
            </ul>
        </div>
        <div class="btton text-center">
            <button type="button" [routerLink]="['/tradie/sign-up']" class="btn btn-primary btn-green">Get Started</button>
        </div>
        <p class="content">Need more regions? Check out our PRO plan. You can also add single regions or upgrade to
            unlimited regions within your account at any time</p>
    </div>
    <div class="subcards col-md-4">
        <div class="sub-header">
            <h5>Pro</h5>
        </div>
        <div class="plan">
            <h3 class="font-weight-bold">$49<span>/Week</span></h3>
        </div>
        <div class="sub-list">
            <ul>
                <li>
                    <span class="icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="text">First 30 days FREE - cancel anytime!</span>
                </li>
                <li>
                    <span class="icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="text">Access jobs in choice of 10 x regions</span>
                </li>
                <li>
                    <span class="icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="text">Quote, book and manage jobs</span>
                </li>
                <li>
                    <span class="icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="text">Accept secure payment</span>
                </li>
                <li>
                    <span class="icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="text">Support from Tradie team</span>
                </li>
            </ul>
        </div>
        <div class="btton text-center">
            <button type="button" [routerLink]="['/tradie/sign-up']" class="btn btn-primary btn-green">Get Started</button>
        </div>
        <p class="content">Need more regions? You can add single regions or upgrade to unlimited regions within your
            account at any time.</p>
    </div>
</div>

<section id="whyTradie">
    <div class="container-fluid">
        <div class="container py-5">
            <h3 class="text-center font-weight-bold mb-4" style="margin-top: 25px;width: 100%;float: left;">Why Tradie?</h3>
            <div class="row">
                <div class="col-12 col-md-4 mb-3 mb-md-0">
                    <img class="mb-5" src="assets/images/weKnowYourWorth.jpg" alt="">
                    <h6 class="font-weight-bold pb-2 mb-3">We know your worth.</h6>
                    <p>You’re the backbone of Tradie.com.au. Talented tradies make the magic happen in homes across
                        Australia…
                        we’re just here to connect you with your next client.</p>
                </div>
                <div class="col-12 col-md-4 mb-3 mb-md-0">
                    <img class="mb-5" src="assets/images/weWorkForYou.jpg" alt="">
                    <h6 class="font-weight-bold pb-2 mb-3">We work for you.</h6>
                    <p>Tradie.com.au was designed to act as your sales team, marketing team and personal assistant.
                        You’re in-demand and we know that! Leave the admin to us so you can focus on your projects.</p>
                </div>
                <div class="col-12 col-md-4">
                    <img class="mb-5" src="assets/images/bigPicture.jpg" alt="">
                    <h6 class="font-weight-bold pb-2 mb-3">We're focused on your big picture.</h6>
                    <p>Most on-demand tradie services promise quick gigs, but we want to do more for our tradies.
                        As you use Tradie.com.au and clients verify your excellent work, you and your business can
                        become a
                        “Trusted Partner” at no extra cost -- this is our top-tier profile and the most visible on
                        Tradie.com.au
                        and will help you take your business to the next level.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="statistics" class="d-none">
    <div class="container-fluid py-5">
        <div class="container">
            <div class="row py-4">
                <div class="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
                    <h1 class="pb-3 mb-3">6 234</h1>
                    <h6 class="font-weight-bold text-center px-4">Jobs currently open for quoting</h6>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
                    <h1 class="pb-2 mb-3">28 178</h1>
                    <h6 class="font-weight-bold text-center px-4">Local businesses use our site to generate more
                        customers</h6>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4 mb-md-0">
                    <h1 class="pb-2 mb-3">2.2M</h1>
                    <h6 class="font-weight-bold text-center px-4">have used our site to hire local businesses just like
                        yours</h6>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <h1 class="pb-2 mb-3">$1.2M</h1>
                    <h6 class="font-weight-bold text-center px-4">Created in job value for Australian tradies annually
                    </h6>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section id="successStories">
    <div class="container-fluid">
        <div class="container py-5">
            <h3 class="text-center font-weight-bold mb-4">Business Success Stories</h3>
            <div>
                <ngx-slick-carousel class="service-slide" #slickModal="slick-carousel" [config]="businessStoriesConfig">
                    <div ngxSlickItem *ngFor="let story of businessStories" class="mx-3 pb-4 mb-3">
                        <div class="image-wrapper">
                            <img class="mb-3 w-100" src="{{story.img}}">
                        </div>
                        <div class="px-3">
                            <h5 class="text-center font-weight-bold">{{story.title}}</h5>
                            <p class="text-center mb-4"><em>{{story.content}}</em></p>
                            <button class="btn btn-primary text-uppercase invisible mx-auto py-1 px-3 rounded-0">Read
                                More</button>
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
    </div>
</section> -->


<div class="assa_block">
    <h4>ASSA add-on <span style="color: #009700;">benefits</span></h4>
<div class="bor_der">
    <div class="assa0Block">
        <h5>Tradie: Brought to you by ASSA Group</h5>
        <p class="textone">Our parent company is ASSA Group, Australia’s leading Workplace Health and Safety compliance system with a
            50+ year track record.</p>
        <!-- <span class="small_txt"><em>Note: Use ASSA 50 years badge somewhere?</em></span> -->
    </div>
</div>


    <h6>Why bundle your Tradie and ASSA memberships?</h6>
    <ul>
        <li>
            <a class="assa_img">
                <img src="../../assets/images/latest_images/pricing2.PNG" />
            </a>
            <p class="headline">Save money</p>
            <p class="text">When you bundle your Tradie and ASSA membership, you’ll get full access to the ASSA WH&S System at an
                exclusive 30% discount… and ASSA doesn’t offer that discount anywhere else.</p>
        </li>
        <li>
            <a class="assa_img">
                <img src="../../assets/images/latest_images/pricing1.PNG" />
            </a>
            <p class="headline">Tackle compliance</p>
            <p class="text">The ASSA System simplifies WHS compliance with industry-specific templates, tools, checklists, inductions
                and guides for sole traders and business owners — everything you need to finally get on top of
                compliance requirements.</p>
        </li>
        <li>
            <a class="assa_img">
                <img src="../../assets/images/latest_images/pricing3.PNG" />
            </a>
            <p class="headline">ASSA knows trade</p>
            <p class="text">Over half of ASSA members are trades people themselves or run a trade-related business, so you can be
                sure the ASSA team has extensive, real-life insight into your needs and priorities as a trade
                professional.</p>
        </li>
        <li>
            <a class="assa_img">
                <img src="../../assets/images/latest_images/pricing4.PNG" />
            </a>
            <p class="headline">Get support</p>
            <p class="text">When you bundle your Tradie and ASSA membership, you’ll get full access to the ASSA WH&S System at an
                exclusive 30% discount… and ASSA doesn’t offer that discount anywhere else.</p>
        </li>
    </ul>
</div>
<landing-tradie-slider-component></landing-tradie-slider-component>
<section id="getApp">
    <div class="apps_block">
        <div class="justify-content-center align-items-center col-12 appb">
            <div class="get-app-img col-md-4">
                <a class="mobile_img">
                    <img src="../../assets/images/latest_images/mobile_img.png" />
                </a>
            </div>
            <div class="col-md-8 app_content">
                <h5 class="mb-5 text-left">Get the App</h5>
                <div class="app-download-main">
                    <p class="text-left mb-3">Find tradies, get quotes and manage jobs from your
                        phone.</p>
                    <p class="f-bold text-left mb-5">Download the Tradie.com.au app now!</p>
                    <div class="app-download-link">
                        <a class="link_imgs">
                            <img src="../../assets/images/latest_images/apple.png" />

                        </a>
                        <div class="availtext-left">
                            <p>Available on</p>
                            <p class="mb-0 f-bold" style="font-size: 18px;">App Store</p>
                        </div>
                    </div>
                    <div class="app-download-link">
                        <a class="link_imgs">
                            <img src="../../assets/images/latest_images/google-play.png" />

                        </a>
                        <div class="availtext-left">
                            <p>Available on</p>
                            <p class="mb-0 f-bold" style="font-size: 18px;">Google Play</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<app-footer-component></app-footer-component>