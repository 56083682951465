<!-- Popular Categories Section -->
<section id="popular-categories">
        <div class="container pb-20 mtp-5">
            <div class="text-center mb-20">
                <h4 class="text-green fw-600">Popular Categories</h4>
            </div>
            <div class="row">
                <div class="col-sm-3 mt-15" *ngFor="let category of PopularCategories">
                    <a [routerLink]="['/client/sign-up']"   >
                        <div class="card width-15-rem">
                            <img class="card-img-top green-bottom-border width-5-rem mt-3" src={{category.image_source}} alt="Card image cap">
                            <div class="card-body">
                              <h5 class="card-title fw-600">{{category.category_name}}</h5>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
</section>
    
<!-- All Categories Section -->
<section class="all-categories">
        <div class="container mtp-6 mbp-10">
            <div class="text-center mb-20">
                <h4 class="text-green fw-600">All Categories</h4>
            </div>
            <div class="row">
            
                <div class="col-sm-3">
                    <!-- A Directory -->
                    <h6 class="green-bottom-border fw-600 mt-30 width-7">A</h6>
                    <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                        <p *ngIf = "category.category_name.indexOf('A') === 0">{{category.category_name}}</p>
                    </a>
                    
                    <!-- B Directory -->
                    <h6 class="green-bottom-border fw-600 mt-30 width-7">B</h6>
                    <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                        <p *ngIf = "category.category_name.indexOf('B') === 0">{{category.category_name}}</p>
                    </a>
                    
                    <!-- C Directory -->
                    <h6 class="green-bottom-border fw-600 mt-30 width-7">C</h6>
                    <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                        <p *ngIf = "category.category_name.indexOf('C') === 0">{{category.category_name}}</p>
                    </a>
                    
                    <!-- D Directory -->
                    <h6 class="green-bottom-border fw-600 width-7">D</h6>
                    <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                        <p *ngIf = "category.category_name.indexOf('D') === 0">{{category.category_name}}</p>
                    </a>
                    
                     <!-- E Directory -->
                     <h6 class="green-bottom-border fw-600 mt-30 width-7">E</h6>
                     <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                         <p *ngIf = "category.category_name.indexOf('E') === 0">{{category.category_name}}</p>
                     </a>
                </div>
                
                <div class="col-sm-3">
                    <!-- F Directory -->
                    <h6 class="green-bottom-border fw-600 mt-30 width-7">F</h6>
                    <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                        <p *ngIf = "category.category_name.indexOf('F') === 0">{{category.category_name}}</p>
                    </a>
                    
                     <!-- G Directory -->
                     <h6 class="green-bottom-border fw-600 width-7">G</h6>
                     <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                         <p *ngIf = "category.category_name.indexOf('G') === 0">{{category.category_name}}</p>
                     </a>
                     
                     <!-- H Directory -->
                    <h6 class="green-bottom-border fw-600 mt-30 width-7">H</h6>
                    <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                        <p *ngIf = "category.category_name.indexOf('H') === 0">{{category.category_name}}</p>
                    </a>
                    
                    <!-- I Directory -->
                    <h6 class="green-bottom-border fw-600 mt-30 width-7">I</h6>
                    <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                        <p *ngIf = "category.category_name.indexOf('I') === 0">{{category.category_name}}</p>
                    </a>
                    
                    <!-- J Directory -->
                    <h6 class="green-bottom-border fw-600 width-7">J</h6>
                    <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                        <p *ngIf = "category.category_name.indexOf('J') === 0">{{category.category_name}}</p>
                    </a>
                    
                    <!-- K Directory -->
                    <h6 class="green-bottom-border fw-600 mt-30 width-7">K</h6>
                    <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                        <p *ngIf = "category.category_name.indexOf('K') === 0">{{category.category_name}}</p>
                    </a>
                    
                     <!-- L Directory -->
                     <h6 class="green-bottom-border fw-600 mt-30 width-7">L</h6>
                     <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                         <p *ngIf = "category.category_name.indexOf('L') === 0">{{category.category_name}}</p>
                     </a>
                </div>
                
                <div class="col-sm-3">
                   <!-- M Directory -->
                   <h6 class="green-bottom-border fw-600 mt-30 width-7">M</h6>
                   <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                       <p *ngIf = "category.category_name.indexOf('M') === 0">{{category.category_name}}</p>
                   </a>
                   
                   <!-- N Directory -->
                   <h6 class="green-bottom-border fw-600 mt-30 width-7">N</h6>
                   <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                       <p *ngIf = "category.category_name.indexOf('N') === 0">{{category.category_name}}</p>
                   </a>
                   
                   <!-- P Directory -->
                   <h6 class="green-bottom-border fw-600 mt-30 width-7">P</h6>
                   <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                       <p *ngIf = "category.category_name.indexOf('P') === 0">{{category.category_name}}</p>
                   </a>
                   
                   <!-- R Directory -->
                   <h6 class="green-bottom-border fw-600 mt-30 width-7">R</h6>
                   <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                       <p *ngIf = "category.category_name.indexOf('R') === 0">{{category.category_name}}</p>
                   </a>
                    
                    <!-- S Directory -->
                   <h6 class="green-bottom-border fw-600 mt-30 width-7">S</h6>
                   <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                       <p *ngIf = "category.category_name.indexOf('S') === 0">{{category.category_name}}</p>
                   </a>
                </div>
                
                <div class="col-sm-3">
                    <!-- T Directory -->
                   <h6 class="green-bottom-border fw-600 mt-30 width-7">T</h6>
                   <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                       <p *ngIf = "category.category_name.indexOf('T') === 0">{{category.category_name}}</p>
                   </a>
                    
                    <!-- U Directory -->
                   <h6 class="green-bottom-border fw-600 mt-30 width-7">U</h6>
                   <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                       <p *ngIf = "category.category_name.indexOf('U') === 0">{{category.category_name}}</p>
                   </a>
                   
                   <!-- V Directory -->
                   <h6 class="green-bottom-border fw-600 mt-30 width-7">V</h6>
                   <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                       <p *ngIf = "category.category_name.indexOf('V') === 0">{{category.category_name}}</p>
                   </a>
                   
                   <!-- W Directory -->
                   <h6 class="green-bottom-border fw-600 mt-30 width-7">W</h6>
                   <a [routerLink]="['/client/sign-up']" *ngFor = "let category of AllCategories">
                       <p *ngIf = "category.category_name.indexOf('W') === 0">{{category.category_name}}</p>
                   </a>
                </div>
                
            </div>
        </div>
</section>
    
