<div class="faqpage">
    <app-main-header></app-main-header>
    <div class="faq mid-container">
        <div class="title-bar">

            <h1>FA<span style="color: #009700;">Q</span></h1>
        </div>
        <div class="quest">

            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-description>
                            1. What is Tradie?

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p>Tradie.com.au is a website and mobile app that makes it easy for homeowners and trusted tradies
                        to connect and get to work.
                         </p>
                    <p>
                        For clients looking to hire, Tradie.com.au is an easy way to find skilled tradespeople in your
                        area, get quotes, book jobs and settle payment. We know you’ll be putting a lot of trust into
                        our tradies and we don’t take that responsibility lightly.
                    </p>
                    <p>Our platform helps tradies take control of their career. We’re a marketing team, sales rep and
                        personal assistant that talented tradies can use to build a business that simplifies their
                        future.
                        Most on-demand tradie services promise quick gigs, but we’re not about quick fixes. It’s the big
                        picture we dream about!
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-description>
                            2. How much does Tradie cost for a homeowner?

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p>Tradie is completely free for homeowners to use. All you have to do is create an account and you
                        can post your first job in minutes!
                         </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-description>
                            3. How does Tradie work for a tradesperson?

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p>At Tradie.com.au, we put a huge amount of focus on business-building tools for our trusted local
                        tradies. To support the maintenance of those tools, we offer our tradies access to local jobs
                        and the tradesperson portal for a flat monthly membership.
                         </p>

                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-description>
                            4. Does Tradie verify a tradesperson’s qualifications? 

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p>At Tradie.com.au, we believe trust is the most important factor in a contract between a
                        tradesperson and a homeowner — no matter how big or small the job may be. We vet tradies when
                        they apply to work with Tradie.com.au and we keep monitoring feedback and results over time.
                        Clients can privately and publicly rate experiences after a job, which helps us monitor
                        performance alongside other quality control systems that are always in play behind the scenes.
                        We do all that so you can hire the right tradie and get the job done right, every time.
                         </p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-description>
                            5. Does Tradie show reviews of a tradesperson’s work? 

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p>Transparency is an important part of trust — and trust is our number one priority. Part of that
                        transparency is the rating listed on every tradie’s profile. The rating is the average of all
                        reviews left by clients about that tradie (good and bad!). We’ll never suppress negative
                        reviews, so the reason you may not see many poorly-rated tradies on the site is this: If a
                        tradie drops below our minimum standard rating, we will terminate our partnership with them and
                        they’ll no longer be able to access jobs on Tradie.com.au.


                         </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-description>
                            6. What work can I hire for on Tradie?

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p>
                        You can hire for just about any trade work on Tradie.com.au! You can find a tradie with highly
                        specialised skills like installing a split air con system or get a trusted local handyman in to
                        fix a dodgy doorknob. Some of the most popular services booked through Tradie.com.au are  
                    </p>

                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-description>
                            7. Who is liable for the work booked through Tradie?

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p>We’re glad you asked! Tradie.com.au is a branch of ASSA Group, a family-owned business with over
                        50 years of experience helping Aussie and NZ businesses navigate Workplace Health and Safety
                        compliance. In other words — we know liability and we are passionate about worksite safety.
                         </p>
                    <p>Ultimately, individual tradespeople are liable for work they book through Tradie.com.au. However,
                        part of our initial vetting process includes thorough checks of WHS/OHS documentation and any
                        certificates or permits the tradesperson should have. </p>
                    <p>
                        We have a deep understanding of work safety compliance and an in-house team of WHS/OHS
                        compliance experts to ensure no work is undertaken with sub-standard WHS/OHS documentation.
                    </p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-description>
                            8.Is Tradie easier than finding a tradesperson elsewhere?
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p> Yes, Tradie.com.au makes it very easy to find a skilled local tradie. Instead of blindly
                        searching for “plumbers near me” or “electricians near me” and sorting through the results, you
                        can post a polished job ad and get it in front of top local tradies in minutes.

                         </p>
                </mat-expansion-panel>
            </mat-accordion>


            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-description>
                            9. What does Tradie do differently than other platforms that connect clients to tradies? 

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p>
                        Our parent company is ASSA Group, Australia’s leading Workplace Health and Safety compliance
                        system with a 50+ year track record of helping businesses protect their people and profits
                        through holistic workplace health and safety (WHS). Because Tradie.com.au was born from a
                        background in WHS compliance, we have a true expert-level understanding of certification
                        requirements, work safety measures and the required compliance documentation.
                         </p>
                    <p>
                        This means that when you use Tradie.com.au, you don’t have to think about whether or not the
                        tradesperson working in your home is up-to-date with their trade accreditation and WHS
                        documentation — we’ve verified that before they had access to jobs on Tradie.com.au.
                    </p>
                    <p>
                        When we say we value trust more than anything, we don’t mean the blind “just trust me” kind of
                        trust you see in the movies before someone jumps from a helicopter. We build trust by looking
                        after people — beginning with comprehensive certification and safety checks and ending with a
                        transparent review system post-job.
                    </p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
<app-footer-component></app-footer-component>