<div style="width: 100%;float:left;">
    <app-main-header></app-main-header>
    <div class="contactPage">
        <h3 style="font-size: 30px;margin-bottom: 30px;">Contact<span style="color: #009700;"> Us</span></h3>
<div class="contact_list">
<ul>
    <li>If you are a tradie and you want to list your business, <a class="links" [routerLink]="['/tradie/sign-up']">Sign Up here</a>
    </li>
    <li>
        If you have an account and you forgot your password, go here.<a class="links" (click)="openDialog()"> Forgot password</a>
    </li>
    <li>
        For website and app technical support, email<span class="text"> support@tradie.com.au.</span>
    </li>
    <li>
        For all other enquiries, just use the form below or email<span class="text"> hello@tradie.com.au!</span> 
    </li>
</ul>


<div class="contact_block">
            

    <form [formGroup]="contactForm" (ngSubmit)="doSubmit()">
        <div class="form-group">
            <label>First Name*</label>
            <input type="text" class="form-control" name="First_Name" formControlName="firstName"
                autocomplete="off" required autofocus
                [ngClass]="{ 'has-error': submitted && f.firstName.errors }" />
            <div *ngIf="submitted && f.firstName.errors" class="error-text">
                <span *ngIf="f.firstName.errors['required']"> First Name is required</span>
            </div>

        </div>
        <div class="form-group">
            <label>Last Name*</label>
            <input type="text" class="form-control" name="Last_Name" formControlName="lastName"
                autocomplete="off" [ngClass]="{ 'has-error': submitted && f.lastName.errors }" required
                autofocus />
            <div *ngIf="submitted && f.lastName.errors" class="error-text">
                <span *ngIf="f.lastName.errors['required']"> Last Name is required</span>
            </div>

        </div>
        <div class="form-group">
            <label>Company*</label>
            <input type="text" class="form-control" name="userId" autocomplete="off" formControlName="company"
                [ngClass]="{ 'has-error': submitted && f.company.errors }" required autofocus />
            <div *ngIf="submitted && f.company.errors" class="error-text">
                <span *ngIf="f.company.errors['required']"> Company name required</span>
            </div>

        </div>


        <div class="form-group">
            <label>Email*</label>
            <input type="text" class="form-control" name="userId" autocomplete="off" formControlName="email"
                [ngClass]="{ 'has-error': submitted && f.email.errors }" required autofocus />
            <div *ngIf="submitted && f.email.errors" class="error-text">
                <span *ngIf="f.email.errors['required']"> Email is required</span>
                <span *ngIf="f.email.errors['email']"> Enter valid email address </span>
            </div>

        </div>
        <div class="form-group two">

            <label>Message*</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" formControlName="description"
                [ngClass]="{ 'has-error': submitted && f.description.errors }" rows="5"></textarea>
                <div *ngIf="submitted && f.description.errors" class="error-text">
                    <span *ngIf="f.description.errors['required']"> Description is required</span>
                   
                </div>
        </div>




        <button type="submit" class="btn btn-primary btn-green">Submit</button>
        <button class="btn btn-primary btn-grey" (click)="reset()">Reset</button>
    </form>

</div>

</div>

        
    </div>

    <app-footer-component></app-footer-component>
</div>