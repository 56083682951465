<div>
    <app-main-header></app-main-header>
    <div id="banner">
        <div class="banner-wrapper banner-overlay container-fluid container-fluid d-flex align-items-center">
            <div class="banner-about-txt container">
                <div class="flex flex-wrap col-grid flex-middle">
                    <div class="col banner-content">
                        <h1 class="text-center mb-4"><em>Find a trusted tradie near you</em></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Renders the child component -->
    <router-outlet></router-outlet>
    
    <app-call-to-action></app-call-to-action>
    <app-footer-component></app-footer-component>
</div>
