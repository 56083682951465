<section id="getApp">
    <div class="container-fluid px-0">
        <div class="d-flex justify-content-center align-items-center container">
            <div class="pr-5">
                <h1 class="mb-3 text-left">Get the <span class="text-green">App</span></h1>
                <div class="app-download-main">
                    <p class="text-left f-bold mb-1">Find tradies, get quotes and manage jobs from your phone.</p>
                    <p class="f-bold text-green mb-3">Download the Tradie.com.au app now!</p>
                    <div class="app-download-link flex flex-middle flex-wrap">
                        <a href="#" class="btn btn-primary d-flex align-items-center justify-content-center px-4 mr-3">
                            <fa-icon class="mr-3" [icon]="faApple"></fa-icon>
                            <div class="text-left">
                                <p class="mb-0">Available on</p>
                                <p class="mb-0">App Store</p>
                            </div>
                        </a>
                        <a href="#" class="btn btn-secondary d-flex align-items-center justify-content-center px-4">
                            <fa-icon class="mr-3" [icon]="faGooglePlay"></fa-icon>
                            <div class="text-left">
                                <p class="mb-0">Available on</p>
                                <p class="mb-0">Google Play</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="get-app-img pt-5 pl-5 mt-5">
                <img class="back" src="/assets/images/mobile-phone2.png" alt="Mobile App 2" />
                <img class="front" src="/assets/images/mobile-phone.png" alt="Mobile App" />
            </div>
        </div>
    </div>
</section>

<section id="quoteCallout">
    <div class="container-fluid">
        <div class="container">
            <div class="row py-5 align-items-center">
                <div class="col-12 col-md-7 offset-md-2">
                    <div class="d-flex">
                        <div class="mr-5">
                            <img src="/assets/images/cs-vector.png">
                        </div>
                        <div>
                            <h2 class="mb-2">Customer Support</h2>
                            <h4 class="font-weight-normal">Get in touch - we're happy to help!</h4>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <a [routerLink]="['/contact_us']" class="btn btn-outline-light text-uppercase px-4 py-2">Contact us</a>
                </div>
            </div>
        </div>
    </div>
</section>