import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as jwtDecode from "jwt-decode";
import { EncryptionService } from '../services/encryption-service/encryption.service';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilityService {

  dateTime = new Date();

  constructor(
    private encryptionService: EncryptionService
  ) { }

  /**
  * Method : timeFormate
  * Purpose : Formate the time
  * @param time as time
  */
  timeFormateWithotDate(time) {
    if (time) {
      let times = time.split(':');
      this.dateTime.setHours(parseInt(times[0]));
      this.dateTime.setMinutes(parseInt(times[1]));
      this.dateTime.setSeconds(parseInt(times[2]));
      return moment(this.dateTime).format('LT');
    }
  }

  decodeToken() {
    const token = localStorage.getItem('accessToken');
    if (token)
      return this.encryptionService.decryptUsingAES256(token) ? jwtDecode(this.encryptionService.decryptUsingAES256(token)) : {};
    return {};
  }

}
