import { Component, Injector, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import Swal from 'sweetalert2';
import { ForgotPasswordComponent } from '../client/client-auth/forgot-password/forgot-password.component';
import { ContactUsService } from '../services/contact-us.service';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  submitted = false

 public contactForm: FormGroup;

  constructor(
   private formBuilder: FormBuilder,
   private contactService : ContactUsService,
   private inject: Injector,
   private router : Router,
   public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      company: ['', [Validators.required]],
      description: ['',[Validators.required]]
    })
  }
  doSubmit(){
    this.submitted = true
    if (this.contactForm.invalid) {
      return false
    }
    this.inject.get(LoaderService).show();
     this.contactService.contactUs(this.contactForm.value).then((res: any) => {
      this.inject.get(LoaderService).hide();
      Swal.fire({
        icon: 'success',
        text: "Thank you for reaching us"
      }
        );
      console.log(res)
      this.router.navigate(['/client']);
      this.contactForm.reset();
      },
      error => {
        this.inject.get(LoaderService).hide();
        console.log(error)
       
      })
  }

  get f() {
    return this.contactForm.controls
  }
  reset(){
    this.contactForm.reset();
  }

  /**
  * Method: openDialog
  * Purpose : view or edit client details open dialog box
  */
   openDialog(): void {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
    });
  }
}
