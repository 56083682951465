import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AgmCoreModule } from '@agm/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JobQuoteDetailsComponent } from './job-quote-details/job-quote-details.component';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BarRatingModule } from 'ngx-bar-rating';
import { NumberDirective } from '../directive/numbers-only.directive';
import { TwoDecimalNumberDirective } from '../directive/two-decimal-number.directive';
import { FooterComponent } from './footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LandingTradieSliderComponent } from './landing-tradie-slider/landing-tradie-slider.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from 'ng2-file-upload';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { JobStatusFilterPipe } from './pipes/job-status-filter.pipe';
import { CreateJobComponent } from './create-job/create-job.component';
import { CallToActionComponent } from './call-to-action/call-to-action.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';




export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    JobQuoteDetailsComponent,
    TwoDecimalNumberDirective,
    NumberDirective,
    FooterComponent,
    LandingTradieSliderComponent,
    DateAgoPipe,
    JobStatusFilterPipe,
    CreateJobComponent,
    CallToActionComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent
    
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAlICf9PUAxIiSVBm8ZMdvykE0tSIQxszE',
      libraries: ['places']
    }),
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BarRatingModule,
    FontAwesomeModule,
    SlickCarouselModule,
    AmazingTimePickerModule,
    NgbModule,
    RouterModule,
    FileUploadModule,
    NgMultiSelectDropDownModule
  ],
  exports: [
    TranslateModule,
    JobQuoteDetailsComponent,
    FooterComponent,
    TwoDecimalNumberDirective,
    NumberDirective,
    LandingTradieSliderComponent,
    DateAgoPipe,
    JobStatusFilterPipe,
    CallToActionComponent
  ],
  providers: [
    DatePipe
  ],
  entryComponents: [
    CreateJobComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent
      
  ],
})
export class SharedModule { }
