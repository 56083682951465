<footer id="footer">
    <div class="footerBlock">
        <div class="logoImage">
            <a class="logo">
                <img class="mb-2" src="/assets/images/logo-white.svg" alt="Tradie Logo">
            </a>
        </div>

        <!-- <p class="text" style="padding-left: 15%;text-align: left;margin-bottom: 20px;">
            Copyright <fa-icon [icon]=" faCopyright" style="color: #fff"></fa-icon> 2021 TRADIE.COM.AU PTY LTD. All
            rights
            reserved
        </p> -->
        <div class="footer_content col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="footer_links col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="left col-lg-3 col-md-3 col-sm-12 col-xs-12" style="border-right: 1px solid #070707;">
                    <h5>Get In Touch</h5>
                    <ul class="address">
                        <li><a (click)='openCreateJob()' style="cursor: pointer;">Submit a Job</a></li>
                        <li><a [routerLink]="['/tradie/sign-up']">Sign Up to Tradie</a></li>
                        <li><a [routerLink]="['/contact_us']">Contact Us</a></li>
                        <li><a [routerLink]="['/faq']">FAQ</a></li>
                    </ul>
                </div>
    
                <div class="left col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <h5>About Us</h5>
                    <ul class="address">
                        <li>33A Success Street </li>
                        <li>Acacia Ridge QLD 4110</li>
                        <li>PO Box 118</li>
                        <li> Archerfield QLD 4108</li>
                        
                    </ul>
                    <!-- <div class="terms"> -->
                        <!-- <div><a (click)="openTermsDialog()">Terms & Conditions</a></div> -->
                        <!-- <div><a [routerLink]="['/terms-conditions']">Terms & Conditions</a></div> -->
                        <!-- <div><a (click)="openPrivacyDialog()"> Privacy Policy</a></div> -->
                        <!-- <div><a [routerLink]="['/privacy-policy']"> Privacy Policy</a></div> -->
                    <!-- </div> -->
                </div>
            </div>
            
            <div class="footer_links col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="left col-lg-3 col-md-3 col-sm-12 col-xs-12" style="border-right: 1px solid #070707 ;">
                    <h5>Contact </h5>
                    <ul class="address">
                        <li>Telephone: 1300 131 014</li>
                        <li>Fax: 1300 720 228</li>
                    </ul>
                </div>
                <div class="left socialLinks col-lg-3 col-md-3 col-sm-12 col-xs-12" style="margin-right: 0;">
                    <h5>Find Us on</h5>
                    <ul class="address">
                        
                        <li><a 
                            target="_blank" class="fb_icon">
                            <img src="../../../assets/images/latest_images/linkedin1.svg" />
                        </a></li>
                        <li><a 
                            target="_blank" class="fb_icon">
                            <img src="../../../assets/images/latest_images/twitter.svg" />
                        </a></li>
                        <li><a href="https://www.facebook.com/assaohs"
                            target="_blank" class="fb_icon">
                            <img src="../../../assets/images/latest_images/facebook_footer.svg" />
                        </a></li>
                        <li><a 
                            target="_blank" class="fb_icon">
                            <img src="../../../assets/images/latest_images/youtube.svg" />
                        </a></li>
                       
                    </ul>
                </div>
            </div>
           

            <!-- <div class="right col-lg-6 col-md-6 col-sm-12 col-xs-12">


                <div class="facebook">
                    <p style="font-size: 20px;font-weight: 700;">Find Us on 
                    </p>
                    <button type="button" style="height: 30px;background-color:#1877f2;
                    padding: 0;
                    width: 80px;
                    font-size: 15px;" class="btn btn- btn-blue" title="Like ASSA's Page on Facebook">
                        <fa-icon style="margin-right: 5px;" [icon]="faThumbsUp"></fa-icon>
                        <span>Like</span>
                    </button>
                </div>
            </div> -->


        </div>

    </div>
    <div class="small_footer">
        <div>
            <!-- <a class="logo_assa">
                <img class="img-responsive" src="/assets/images/ASSA_logo.svg" alt="Tradie Logo" />
            </a> -->
            <p>Copyright 2021 TRADIE.COM.AU PTY LTD. All rights reserved</p>
        </div>
    </div>
</footer>