import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobDataService {

  jobType = [];
  jobAddress = [];
  jobSerice: any;
  jobDate: any;
  jobDescription = [];
  jobQuestions = [];
  jobContactDetail: any;
  jobTypeData = [];
  files = [];
  jobCriteria = [];

  public isBack: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isJobQuestionsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }


  setjobType(data) {
    this.jobType = data;
  }

  getjobType() {
    return this.jobType;
  }

  getjobTypeData() {
    return this.jobTypeData;
  }

  setjobTypeData(data) {
    this.jobTypeData = data;
  }

  setjobAddress(data) {
    this.jobAddress = data;
  }

  getjobAddress() {
    return this.jobAddress;
  }

  setjobSerice(data) {
    this.jobSerice = data;
  }

  getjobSerice() {
    return this.jobSerice;
  }


  setjobDate(data) {
    this.jobDate = data;
  }

  getjobDate() {
    return this.jobDate;
  }

  setjobDescription(data) {
    this.jobDescription = data;
  }

  getjobDescription() {
    return this.jobDescription;
  }

  setjobQuestions(data) {
    this.jobQuestions = data;
  }

  getjobQuestions() {
    return this.jobQuestions;
  }

  setjobContactDetail(data) {
    this.jobContactDetail = data;
  }

  getjobContactDetail() {
    return this.jobContactDetail;
  }

  setFiles(data) {
    this.files = data;
  }

  getFiles() {
    return this.files;
  }

  setCriteria(data) {
    this.jobCriteria = data;
  }

  getCriteria() {
    return this.jobCriteria;
  }

  clearData() {
    this.jobType = [];
    this.jobAddress = [];
    this.jobSerice = "";
    this.jobDate = "";
    this.jobDescription = [];
    this.jobQuestions = [];
    this.jobContactDetail = "";
    this.jobTypeData = [];
    this.files = [];
    this.jobCriteria = [];
  }
}
