import { Component, OnInit, ViewChild, ElementRef, NgZone, Injector } from '@angular/core';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { MatDialog, MatDialogRef } from '@angular/material';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader } from '@agm/core';
import { DatePipe } from '@angular/common';
import { FileUploader } from 'ng2-file-upload';
import { GoogleLoginProvider, FacebookLoginProvider, AuthService } from 'angularx-social-login';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { JobDataService } from '../../client/client-core/client-job/job-data.service';
import { EncryptionService } from '../../services/encryption-service/encryption.service';
import { ClientAuthService } from 'src/app/services/client-services/auth/client-auth.service';
import { ProfileService } from 'src/app/services/client-services/profile/profile.service';
import { LoaderService } from 'src/app/loader/loader.service';
import { QuotesService } from 'src/app/services/client-services/quotes/quotes.service';
import { faBriefcase, faFileAlt, faPaperclip, faQuestionCircle, faClipboardCheck, faClock, faTimesCircle, faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { ForgotPasswordComponent } from 'src/app/client/client-auth/forgot-password/forgot-password.component';
import { MustMatch } from 'src/app/client/client-auth/mustmatch.validator';

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';
const { detect } = require('detect-browser');

@Component({
  selector: 'app-create-job',
  templateUrl: './create-job.component.html',
  styleUrls: ['./create-job.component.scss']
})

export class CreateJobComponent implements OnInit {

  @ViewChild("address", { static: false }) public searchElementRef: ElementRef;
  @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;

  newJobForm: FormGroup;

  stepNumber: number = 1;
  subStepNumber: number = 1;
  toAddDetails: boolean = false;
  isRegistration: boolean = false;
  isLogin: boolean = false;
  isMobileRegistration: boolean = false;
  isOtpValidation: boolean = false;
  isJobSaved: boolean = false;

  modalTitle: string = 'Tell us what you need';
  modalButtonText: string = 'Next';

  isLoading: boolean = false;
  isSuccess: boolean = false;
  hasError: boolean = false;

  serviceList: any = [];
  serviceDropdownSettings = {};
  jobTypeDropdownSettings = {};

  jobTypeList: any = [];
  jobTimeList: any = [];

  dateToday: Date = new Date();
  rawSelectedTime: string = '';

  hasTradie: boolean = true;
  latitude: any;
  longitude: any;
  postcode: any;
  locality: any;
  postcodeNumber: any;
  regionId: any;
  postcodeId: any;
  zoom: number = 0;

  uploader: FileUploader;
  files = [];
  thumb = [];
  ready = false;

  summary: any = {};
  summaryMaxDescription: number = 300;
  summaryReadMore: boolean = false;

  additionalQuestions: any = [];
  additionalQuestionsMaxSteps: number = 0;

  noLoginFound = false;
  errorMessage = '';
  loginRequest = {
    email: '',
    password: '',
    roleId: 2,
    loginType: 1,
    socialId: '',
    deviceId: '',
    deviceType: ''
  };
  userId: any;
  roleId: any;
  clientId: any;
  phoneNumber: any;
  countryCode = '+61'
  passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,50}$/;

  jobCreateRequest = {
    name: '', contactNumber: '', email: '', description: ''
    , state: '', postCode: '', address: '', locality: '', opportunityTimeId: 0, regionId: 0
    , opportunityTime: '', opportunityJobDate: '', opportunityCategoryId: ''
    , requestTypes: [], documents: [], jobTypeAnswers: [], tradieId: ''
    , latitude: '', longitude: '', clientId: 0
  };

  registrationRequest = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    // phoneNumber: '',
    roleId: 2,
    loginType: 1,
    socialId: '',
    deviceId: ''
  };

  isDropdownOpen: boolean = false;

  faBriefcase = faBriefcase;
  faFileAlt = faFileAlt;
  faPaperclip = faPaperclip;
  faQuestionCircle = faQuestionCircle;
  faClipboardCheck = faClipboardCheck;
  faClock = faClock;
  faTimesCircle = faTimesCircle;
  faCircle = faCircle;
  faCheckCircle = faCheckCircle;

  faFacebookSquare = faFacebookSquare;
  isSignIn: boolean;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateJobComponent>,
    private authService: AuthService,
    private timePicker: AmazingTimePickerService,
    private router: Router,
    private datePipe: DatePipe,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private inject: Injector,
    private formBuilder: FormBuilder,
    private jobDataService: JobDataService,
    private encryptionService: EncryptionService,
    private clientAuthService: ClientAuthService,
    private profileService: ProfileService,
    private quotesService: QuotesService
  ) {
    this.uploader = new FileUploader({
      url: URL,
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
  }

  ngOnInit() {
    this.initializationForm();
    this.getServiceList();

    this.serviceDropdownSettings = {
      singleSelection: true,
      idField: 'opportunityCategoryId',
      textField: 'categoryName',
      enableCheckAll: false,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.jobTypeDropdownSettings = {
      singleSelection: false,
      idField: 'skillId',
      textField: 'skillName',
      enableCheckAll: false,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.uploader.onAfterAddingFile = (file) => {
      this.files.push(file._file);
      this.modalButtonText = 'Next';
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.ready = true;
        if (file._file.type == "application/pdf") {
          this.thumb.push({ url: "assets/images/pdf.svg", name: file._file.name });
        } else {
          this.thumb.push({ url: reader.result.toString(), name: file._file.name });
        }
      });
      reader.readAsDataURL(file._file);
    }
  }

  ngAfterViewInit() {
    this.getJobTimeList();
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"],
        componentRestrictions: { country: "au" }
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = String(place.geometry.location.lat().toFixed(6));
          this.longitude = String(place.geometry.location.lng().toFixed(6));
          this.zoom = 15;
          const data = {
            postalCode: place.address_components.filter(obj => {
              return obj.types.includes('postal_code')
            }).map(item => {
              return item.long_name;
            }),
            locality: place.address_components.filter(obj => {
              return obj.types.includes('locality')
            }).map(item => {
              return item.long_name;
            })
          }

          this.postcode = data.postalCode[0];
          this.locality = data.locality[0];

          this.jobControl.patchValue({
            address: place.formatted_address
          });

        });
      });
    });
  }

  /**
  * get : f
  * Purpose : returns form controls value
  * @return form controls
  */
  get f() { return this.newJobForm.controls; }

  /**
  * get : jobControl
  * Purpose : returns form controls value
  * @return form controls
  */
  get jobControl() { return this.newJobForm.controls.job; }

  /**
  * get : detailsControl
  * Purpose : returns form controls value
  * @return form controls
  */
  get detailsControl() { return this.newJobForm.controls.details; }

  /**
  * get : additionalDetailsQuestion
  * Purpose : returns form array item value
  * @return form array item 
  */
  get additionalDetailsQuestion() { return this.f.questions; }

  /**
 * get : additionalDetailsQuestionArray
 * Purpose : returns form array item value
 * @return form array item 
 */
  get additionalDetailsQuestionArray() { return this.f.questions as FormArray; }

  /**
  * get : newUserControl
  * Purpose : returns form controls value
  * @return form controls
  */
  get newUserControl() { return this.newJobForm.controls.newUser; }

  /**
  * get : userLogin
  * Purpose : returns form controls value
  * @return form controls
  */
  get userLoginControl() { return this.newJobForm.controls.userLogin; }

  /**
  * Method : initializationForm
  * Purpose : First time Form initialization
  */
  initializationForm() {
    this.newJobForm = this.formBuilder.group({
      job: this.formBuilder.group({
        service: ['', [Validators.required]],
        type: [{ value: [], disabled: true }, [Validators.required]],
        address: ['', [Validators.required]]
      }),
      details: this.formBuilder.group({
        description: ['', [Validators.required]],
        appointment: ['', [Validators.required]],
        date: [{ value: '', disabled: true }, [Validators.required]],
        time: [{ value: '', disabled: true }, [Validators.required]]
      }),
      questions: new FormArray([]),
      budget: [''],
      jobStatus: [''],
      newUser: this.formBuilder.group({
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        email: ['', [Validators.required]],
        password: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
        confirmPassword: ['', [Validators.required]]
      },
      {
        validator: MustMatch('newPassword', 'confirmPassword')
      }),
      userLogin: this.formBuilder.group({
        email: ['', [Validators.required]],
        password: ['', [Validators.required]]
      }),
       phoneNumber: [this.countryCode,
        [Validators.required,
        Validators.maxLength(12),
        Validators.minLength(12),
        Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{9}$/)]],
      otp: ['', [Validators.required, Validators.minLength(4)]]
    })
  }

  /**
  * Method: nextStep
  * Purpose: go to the next step of the wizard
  */
  nextStep() {
    if (this.stepNumber < 4) {
      this.isRegistration = false;
      this.isLogin = false;
      if (this.stepNumber == 1) {
        if (this.jobControl.invalid) {
          this.hasError = true;
          return;
        }
        if (!this.hasTradie) {
          return;
        }
        sessionStorage.setItem('jobService', this.encryptionService.encryptUsingAES256(JSON.stringify(this.jobControl.value.service)));
        sessionStorage.setItem('jobType', this.encryptionService.encryptUsingAES256(JSON.stringify(this.jobControl.value.type)));
        sessionStorage.setItem('jobAddress', this.encryptionService.encryptUsingAES256(this.jobControl.value.address));
        this.getJobTypeQuestionList();
      }
      if (this.stepNumber == 2) {
        if (this.detailsControl.invalid) {
          this.hasError = true;
          return;
        }
        sessionStorage.setItem('jobDescription', this.encryptionService.encryptUsingAES256(this.detailsControl.value.description));
        sessionStorage.setItem('jobAppointmentId', this.encryptionService.encryptUsingAES256(this.detailsControl.value.appointment));
        sessionStorage.setItem(
          'jobAppointmentDate',
          this.detailsControl.value.date == undefined ? '' :
            this.encryptionService.encryptUsingAES256(this.datePipe.transform(this.detailsControl.value.date, "yyyy-MM-dd"))
        );
        sessionStorage.setItem(
          'jobAppointmentTime', this.encryptionService.encryptUsingAES256(this.rawSelectedTime)
        );
      }
      if (this.stepNumber == 3) {
        sessionStorage.setItem('attachments', JSON.stringify(this.thumb));
        this.jobDataService.setFiles(this.files);
        this.modalTitle = 'Job Summary';
        this.loadSummary();
      }
      this.hasError = false;
      if (this.stepNumber == 3 && this.toAddDetails) {
        this.stepNumber = 5;
        this.subStepNumber = 1;
      } else {
        this.stepNumber++;
      }
    } else if (this.stepNumber == 5) {
      this.isRegistration = false;
      if (this.subStepNumber < this.additionalQuestionsMaxSteps) {
        this.subStepNumber++;
      } else {
        sessionStorage.setItem('jobQuestions', this.encryptionService.encryptUsingAES256(JSON.stringify(this.additionalDetailsQuestionArray.value)));
        sessionStorage.setItem('jobBudget', this.encryptionService.encryptUsingAES256(this.f.budget.value));
        sessionStorage.setItem('jobStatus', this.encryptionService.encryptUsingAES256(this.f.jobStatus.value));
        this.loadSummary();
        this.stepNumber++;
      }
    } else {
      if (this.stepNumber == 4) {
        this.isRegistration = false;
        // this.isLogin = true;
        this.modalTitle = 'Login to continue';
        this.stepNumber = 7;
      }
      if (this.stepNumber == 6) {
        this.isRegistration = false;
        // this.isLogin = true;
        this.modalTitle = 'Login to continue';
        this.stepNumber++;
      }
    }
    if (this.stepNumber == 3) {
      if (this.thumb.length < 1) {
        this.modalButtonText = 'Skip';
        this.isLogin = false;
      } else {
        this.modalButtonText = 'Next';
      }
    } else if (this.stepNumber == 4 || this.stepNumber == 6) {
      this.modalButtonText = 'Finish';
      this.isLogin = false;
    } else {
      this.modalButtonText = 'Next';
    }
  }

  backStep(num) {
    this.stepNumber = num;
    if (this.stepNumber == 3 && this.thumb.length < 1) {
      this.modalButtonText = 'Skip';
    } else {
      this.modalButtonText = 'Next';
    }
  }

  /**
  * Method: addMoreDetails
  * Purpose: go to optional details
  */
  addMoreDetails() {
    this.isRegistration = false;
    this.isLogin = false;
    this.modalTitle = 'Tell us what you need';
    this.modalButtonText = 'Next';
    this.toAddDetails = true;
    this.hasError = false;
    this.stepNumber++;
  }

  /**
  * Method:  getServiceList
  * Purpose : Get Service List
  */
  async getServiceList() {
    await this.quotesService.getServiceList().then((res: any) => {
      if (res != null) {
        this.serviceList = res.data;
      }
    }, err => {
      console.log(err);
    });
  }

  /**
  * Method:  getJobTypeList
  * Purpose : Get job type List
  */
  getJobTypeList() {
    this.jobControl.get('type').enable();
    this.quotesService.getJobTypeList(this.jobControl.value.service[0].opportunityCategoryId).then((res: any) => {
      if (res.data != null) {
        this.jobTypeList = res.data;
      }
    }, err => {
      console.log(err);
    });
  }

  /**
  * Method:  getJobTimeList
  * Purpose : Get job time List
  */
  getJobTimeList() {
    this.quotesService.getJobTimeList().then((res: any) => {
      if (res != null) {
        this.jobTimeList = res;
      }
    }, err => {
      console.log(err);
    });
  }

  /**
  * Method:  resetJobTypeList
  * Purpose : Reset job type List
  */
  resetJobTypeList() {
    this.jobControl.get('type').disable();
  }

  /**
  * Method:  appointmentTypeChange
  * Purpose : Enable / Disable date & time based on selected appointment type
  */
  appointmentTypeChange() {
    if (this.detailsControl.value.appointment == 5) {
      this.detailsControl.get('date').enable();
      this.detailsControl.get('time').enable();
    } else {
      this.detailsControl.get('date').disable();
      this.detailsControl.get('time').disable();
    }
  }

  openTimePicker() {
    const amazingTimePicker = this.timePicker.open();
    amazingTimePicker.afterClose().subscribe(time => {
      this.rawSelectedTime = time;
      this.detailsControl.get('time').patchValue(this.datePipe.transform(this.toTime(time), "shortTime"));
    });
  }

  toTime(timeString) {
    var timeTokens = timeString.split(':');
    return new Date(1970, 0, 1, timeTokens[0], timeTokens[1]);
  }

  /**
    * Method: onRemove
    * Purpose : Change job category retrieve other data
    * @param val as document name 
    */
  onRemove(val: any) {
    for (var i = 0; i < this.files.length; i++) {
      if (this.files[i].name == val) {
        this.thumb.splice(i, 1);
        this.files.splice(i, 1);
        break;
      }
    }
    if (this.files.length < 1) {
      this.modalButtonText = 'Skip';
    }
  }

  /** 
  * Method: loadSummary
  * Purpose: Get data from sessionStorage for Job Summary details 
  */
  loadSummary() {
    this.summary = {
      service: JSON.parse(this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobService')))[0].categoryName,
      jobTypes: JSON.parse(this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobType'))),
      address: this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobAddress')),
      description: this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobDescription')),
      appointment: this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobAppointmentId')),
      jobDate: sessionStorage.getItem('jobAppointmentDate') != '' ? this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobAppointmentDate')) : '',
      jobTime: sessionStorage.getItem('jobAppointmentTime') != '' ? this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobAppointmentTime')) : '',
      attachments: JSON.parse(sessionStorage.getItem('attachments'))
    }
    if (this.stepNumber == 5) {
      this.summary.questions = JSON.parse(this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobQuestions')));
      this.summary.budget = this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobBudget'));
      this.summary.status = this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobStatus'));
    }
  }

  /**
  * Method : getJobTypeQuestionList
  * Purpose : Get job type question List
  */
  async getJobTypeQuestionList() {

    let selectedJobTypes = [];

    for (let i = 0; i < this.jobControl.value.type.length; i++) {
      selectedJobTypes.push(this.jobControl.value.type[i].skillId);
    }

    let val = selectedJobTypes.reduce((current, value, index) => {
      if (index > 0)
        current += ',';
      return current + value;
    }, '');

    await this.quotesService.getJobTypeQuestionList(val).then((res: any) => {
      if (res != null) {
        this.additionalQuestions = res.data;
      }
    }, err => {
      console.log(err);
    });

    if (this.additionalQuestions.length > 0) {
      for (let i = 0; i < this.additionalQuestions.length; i++) {
        this.additionalDetailsQuestionArray.push(this.formBuilder.group({
          opportunityQuestionId: [this.additionalQuestions[i].opportunityQuestionId],
          question: [this.additionalQuestions[i].question],
          answer: ['']
        }));
      }
    }

    this.additionalQuestionsMaxSteps = this.additionalQuestions.length + 2;
  }

  /**
  * Method: signInWithFB
  * Purpose: login using facebook
  */
  async signInWithFB() {
    this.noLoginFound = false;
    await this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => {
      this.inject.get(LoaderService).show();
      this.loginRequest.email = x.email;
      this.loginRequest.socialId = x.id;
      this.loginRequest.deviceType = 'WEB';
      this.loginRequest.loginType = 2;
      this.dialogRef.close();
      this.login();
    });
  }

  /**
  * Method: signInWithGoogle
  * Purpose: login using google
  */
  async signInWithGoogle() {
    this.noLoginFound = false;
    await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
      this.inject.get(LoaderService).show();
      this.loginRequest.email = x.email;
      this.loginRequest.socialId = x.id;
      this.loginRequest.deviceType = 'WEB';
      this.loginRequest.loginType = 3;
      this.dialogRef.close();
      this.login();
    });
  }

  /**
  * Method:  login
  * Purpose : login with tradie
  */
  async login() {
    if (this.isLogin) {
      this.inject.get(LoaderService).show();
    }

    const browser = detect();
    this.loginRequest.deviceId = browser.name;

    if (this.isRegistration) {
      this.loginRequest.email = this.newUserControl.value.email;
      this.loginRequest.password = this.newUserControl.value.password;
      this.loginRequest.deviceType = 'WEB';
      this.loginRequest.loginType = 4;
    }

    await this.clientAuthService.login(this.loginRequest).then((res: any) => {
      if (res) {
        var decoded = jwt_decode(res.accessToken);
        localStorage.setItem('accessToken', this.encryptionService.encryptUsingAES256(res.accessToken));
        localStorage.setItem('refreshToken', res.refreshToken);
        localStorage.setItem('email', decoded.email);
        let userId = this.encryptionService.encryptUsingAES256(decoded.userId)
        localStorage.setItem('userId', userId);
        let roleId = this.encryptionService.encryptUsingAES256(decoded.roleId)
        localStorage.setItem('roleId', roleId);
        localStorage.setItem('clientId', this.encryptionService.encryptUsingAES256(decoded.clientId));

        this.userId = decoded.userId;
        this.roleId = decoded.roleId;
        this.clientId = decoded.clientId;

        // Check if user's mobile is validated
        this.profileService.userProfile(decoded.clientId).then((res: any) => {
          this.jobCreateRequest.name = res.firstName;
          this.jobCreateRequest.contactNumber = this.isRegistration ? 'xxxxxxxxxx' : res.phoneNumber;
          this.jobCreateRequest.clientId = res.clientId;

          localStorage.setItem('phoneNumber', this.encryptionService.encryptUsingAES256(res.phoneNumber));

          this.phoneNumber = res.phoneNumber;

          this.createJobRequest();
        }).catch(err => {
          console.log(err)
          this.authService.signOut();
          this.inject.get(LoaderService).hide();
        })
        if (this.isLogin) {
          this.dialogRef.close();
        }
      }
    }).catch(err => {
      console.log(err);
      if (this.loginRequest.loginType == 3 || this.loginRequest.loginType == 2) {
        this.authService.signOut();
      }
      this.noLoginFound = true;
      this.errorMessage = err.error.message;
      this.inject.get(LoaderService).hide();
    });
  }

  async onSubmit() {
    this.hasError = false;
    if (this.isRegistration) {
      this.isLoading = true;
      if (this.isMobileRegistration) {
        if (this.newJobForm.controls.phoneNumber.invalid) {
          this.hasError = true;
          this.isLoading = false;
          return;
        }
        if (this.isOtpValidation) {
          if (this.newJobForm.controls.otp.invalid) {
            this.hasError = true;
            this.isLoading = false;
            return;
          }
          let request: any = {};
          request.roleId = this.roleId;
          request.userId = this.userId;
          request.otp = this.newJobForm.value.otp;
          this.clientAuthService.verifyOtp(request).then((res: any) => {
            this.isLoading = false;
            this.isOtpValidation = false;
            this.isMobileRegistration = false;
          }).catch(err => {
            this.isLoading = false;
            console.log(err);
          });
        } else {
          let request: any = {};
          request.roleId = this.roleId;
          request.userId = this.userId;
          request.phoneNumber = this.newJobForm.value.phoneNumber;
          this.clientAuthService.updateMobileNumber(request).then((res: any) => {
            this.isLoading = false;
            this.isOtpValidation = true;
          }).catch(err => {
            this.isLoading = false;
            console.log(err);
          });
        }

      } else {
        const browser = detect();
        if (this.newUserControl.invalid) {
          this.hasError = true;
          return;
        }
        this.registrationRequest.firstName = this.newUserControl.value.firstName;
        this.registrationRequest.lastName = this.newUserControl.value.lastName;
        this.registrationRequest.email = this.newUserControl.value.email;
        this.registrationRequest.password = this.newUserControl.value.password;
        this.registrationRequest.deviceId = browser.name;
        await this.signUp();
      }
    }
    if (this.isLogin || this.isSignIn) {
      if (this.userLoginControl.invalid) {
        this.hasError = true;
        return;
      }
      this.loginRequest.email = this.userLoginControl.value.email;
      this.loginRequest.password = this.userLoginControl.value.password;
      this.loginRequest.deviceType = 'WEB';
      this.loginRequest.loginType = 1;
      this.login();
    }
  }

  /**
  * Method:  createJob
  * Purpose: save new job after successful login
  */
  async createJobRequest() {
    await this.getPostlcodeValidate();

    let questionsarray = [];
    if (sessionStorage.getItem('jobQuestions')) {
      const questions = JSON.parse(this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobQuestions')));
      questions.forEach(element => {
        questionsarray.push({ opportunityQuestionId: element.opportunityQuestionId, answer: element.answer });
      });
    }

    let jobtypeArray = [];
    let jobtypevalue: string = "";
    let types = JSON.parse(this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobType')));

    types.forEach(element => {
      jobtypeArray.push(parseInt(element.skillId));
      let result = this.jobTypeList.filter(x => x.skillId == parseInt(element.skillId));
      if (result) {
        jobtypevalue = jobtypevalue == "" ? result[0].skillName : jobtypevalue + ", " + result[0].skillName;
      }
    });

    this.jobCreateRequest.email = localStorage.getItem('email');
    this.jobCreateRequest.description = this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobDescription'));
    this.jobCreateRequest.address = this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobAddress'));
    this.jobCreateRequest.postCode = this.postcodeNumber;
    this.jobCreateRequest.regionId = this.regionId;
    this.jobCreateRequest.opportunityTimeId = parseInt(this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobAppointmentId')));
    this.jobCreateRequest.opportunityTime = sessionStorage.getItem('jobAppointmentTime') != '' ? this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobAppointmentTime')) : '';
    this.jobCreateRequest.opportunityJobDate = sessionStorage.getItem('jobAppointmentDate') != '' ? this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobAppointmentDate')) : '';
    this.jobCreateRequest.opportunityCategoryId = JSON.parse(this.encryptionService.decryptUsingAES256(sessionStorage.getItem('jobService')))[0].opportunityCategoryId;
    this.jobCreateRequest.requestTypes = jobtypeArray;
    this.jobCreateRequest.documents = [];
    this.jobCreateRequest.jobTypeAnswers = questionsarray;
    this.jobCreateRequest.latitude = this.latitude;
    this.jobCreateRequest.longitude = this.longitude;
    this.jobCreateRequest.locality = this.locality;
    await this.saveNewJob();
  }

  /**
  * Method:  getRegion
  * Purpose : Get region list
  */
  async getPostlcodeValidate() {
    let tradieId = 0;
    await this.quotesService.getPostlcodeValidate({ postCodeNumber: this.postcode, tradieId: tradieId, locality: this.locality }).then((res: any) => {
      this.postcodeNumber = res.postcodeNumber;
      this.postcodeId = res.postcodeId;
      this.regionId = res.regionId;
      this.hasTradie = true;
    }, err => {
      this.hasTradie = false;
    });
  }

  /** 
  * Method: saveNewJob
  * Purpose: Save new job request to server 
  */
  async saveNewJob() {
    const storedFiles = JSON.parse(sessionStorage.getItem('attachments'));
    if (storedFiles.length > 0) {
      for (let i = 0; i < Object.keys(this.files).length; i++) {
        let uploadData = new FormData();
        uploadData.append("file", this.files[i]);

        uploadData.append("uploadType", "document");

        await this.quotesService.fileUpload(uploadData).then((res: any) => {
          this.jobCreateRequest.documents.push(res.guid);
        }).catch(err => {
          console.log(err);
        })

      }
    }

    await this.quotesService.createJobQuote(this.jobCreateRequest).then((res: any) => {
      if (res) {
        this.isJobSaved = true;
        if (!this.isRegistration) {
          this.inject.get(LoaderService).hide();
          this.router.navigate(['client/jobs'], { queryParams: { job: res.opportunityId, status: 'Success' } });
        } else {
          this.isLoading = false;
          this.isSuccess = true;
          this.isMobileRegistration = true;
        }
        sessionStorage.clear();
      }
    }, err => {
      this.isJobSaved = false;
      this.isLoading = false;
      this.inject.get(LoaderService).hide();
      console.log(err);
    });
  }

  toAdditionalQuestions() {
    this.modalButtonText = 'Next';
    this.stepNumber = 5;
    this.subStepNumber = 1;
  }

  return() {
    return;
  }

  /**
  * Method : signUp
  * Purpose : signUp the user
  */
  async signUp() {
    await this.clientAuthService.signUp(this.registrationRequest).then((res: any) => {
      if (res) {
        this.userId = res.userId;
        this.roleId = this.registrationRequest.roleId;
        this.jobCreateRequest.name = this.registrationRequest.firstName;
        this.jobCreateRequest.clientId = res.clientId;
        this.isLoading = false;
        this.isMobileRegistration = true;
        this.login();
      }
    }).catch(err => {
      console.log(err.error.message);
      if (err.status == 409) // Checks if status is 409-conflict; is returned from the user signup endpoint
      {
        console.log('ERROR 409');
      }
      this.isLoading = false;
    })
  }
 /**
  * Method: openDialog
  * Purpose : view or edit client details open dialog box
  */
  openDialog(): void {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
    });
  }
}
