<app-main-header></app-main-header>

<div class="sub-page-banner container-fluid">
    <div class="container py-5">
        <div class="row py-5">
            <div class="col">
                <h2 class=""><span style="font-weight: 700;font-size: 34px;color: #000;">“Quick and easy” isn’t the most</span><span
                        class="d-block" style="color: #009700;font-weight: 700;font-size: 30px;padding-left: 2%;margin-top: 15px;">Important part of hiring a tradie.</span></h2>
            </div>
        </div>
    </div>
</div>

<div class="container" style="position: relative;min-height: 230px;">
    <div class="row py-5">
        <div class="col position-absolute">
            <h4 class="text-center text-muted text-open mb-4">We designed Tradie.com.au to make it as easy as
                    possible for clients and tradies to connect and get to work.But most of our
                        effort goes into finding tradies with outstanding repute and partnering with them.Clients trust tradies with their home and a tradie’s work is their
                        future.</h4>
            <h2 class="text-center font-weight-bold" style="font-size: 28px;">The most important part of hiring a tradie is the people.</h2>
        </div>
    </div>
</div>

<div class="container-fluid pb-2">
    <div class="row flex-xl-row-reverse">
        <div class="col-xs-12 col-xl-6 pr-xl-0 pl-xl-1">
            <div id="forTradieSeekers" class="d-none d-xl-block w-100 h-100"></div>
            <img class="d-xl-none w-100" src="../../assets/images/for-tradie-seekers-img.jpg">
        </div>
        <div class="col-xs-12 col-xl-6 pl-xl-0 pr-xl-1">
            <div class="p-xl-5 text-center h-100 bg-secondary">
                <p class="pt-5 mb-3 text-primary text-uppercase font-weight-bold">FOR TRADIE seekers</p>
                <h2 class="font-weight-bold mb-4">If you need a tradie…<span class="d-block">you’re in the right
                        place!</span></h2>
                <div class="px-xl-5 mx-xl-5 text-muted text-open mb-5">
                    <h6 class="mb-4">For clients looking to hire, Tradie.com.au is an easy way to find skilled
                            tradespeople in your area, get quotes, book jobs and settle payment. We know you’ll be
                            putting a lot of trust into our tradies and we don’t take that responsibility lightly.
                    </h6>
                    <h6 class="mb-4">We vet tradies when they apply to work with Tradie.com.au and we keep
                            monitoring feedback and results over time. Clients can privately and publicly rate
                            experiences after a job, which helps us monitor performance alongside other quality control
                            systems that are always in play behind the scenes.</h6>
                    <h6>We do all that so you can hire the right tradie and get the job done right, every time.
                    </h6>
                </div>
                <button class="btn btn-primary btn-green px-4 py-2 text-uppercase mb-3 mb-xl-0"  style="width: auto;" (click)='openCreateJob()'>Post a
                    Job</button>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-xs-12 col-xl-6 pl-xl-0 pr-xl-1">
            <div id="forTradies" class="d-none d-xl-block w-100 h-100"></div>
            <img class="d-xl-none w-100" src="../../../../assets/images/for-tradies-img.jpg">
        </div>
        <div class="col-xs-12 col-xl-6 pr-xl-0 pl-xl-1">
            <div class="p-xl-5 text-center h-100 bg-secondary">
                <p class="pt-5 mb-3 text-primary text-uppercase font-weight-bold">FOR TRADIES</p>
                <h2 class="font-weight-bold mb-4">If you are a tradie…<span class="d-block">we want to hear from
                        you!</span></h2>
                <div class="px-xl-5 mx-xl-5 text-muted text-open mb-5">
                    <h6 class="mb-4">Our platform helps tradies take control of their career. We’re a marketing
                            team, sales rep and personal assistant that talented tradies can use to build a business
                            that simplifies their future.</h6>
                    <h6 class="mb-4">Most on-demand tradie services promise quick gigs, but we’re not about quick
                            fixes. It’s the big picture we dream about!</h6>
                    <h6>As you use Tradie.com.au and clients verify your excellent work, you and your business can
                            become a “Trusted Partner” at no extra cost -- this is our top-tier profile and the most
                            visible on Tradie.com.au. It’ll help you take your business to the next level!</h6>
                </div>
                <button style="width: auto;" class="btn btn-primary btn-green px-4 py-2 text-uppercase mb-3 mb-xl-0"
                    [routerLink]="['/tradie/sign-up']">Sign Up</button>
            </div>
        </div>
    </div>
</div>

<section id="getApp">
    <div class="apps_block">
        <div class="justify-content-center align-items-center col-12 appb">
            <div class="get-app-img col-md-4">
                <a class="mobile_img">
                    <img src="../../assets/images/latest_images/mobile_img.png" />
                </a>
            </div>
            <div class="col-md-8 app_content">
                <h5 class="mb-5 text-left">Get the Tradie App</h5>
                <div class="app-download-main">
                    <p class="text-left mb-3">Find tradies, get quotes and manage jobs from your phone.</p>
                    <p class="f-bold text-left mb-5">Download Now!</p>
                    <div class="app-download-link">
                        <a class="link_imgs">
                            <img src="../../assets/images/latest_images/apple.png" />

                        </a>
                        <div class="availtext-left">
                            <p>Available on</p>
                            <p class="mb-0 f-bold" style="font-size: 18px;">App Store</p>
                        </div>
                    </div>
                    <div class="app-download-link">
                        <a class="link_imgs">
                            <img src="../../assets/images/latest_images/google-play.png" />

                        </a>
                        <div class="availtext-left">
                            <p>Available on</p>
                            <p class="mb-0 f-bold" style="font-size: 18px;">Google Play</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<app-footer-component></app-footer-component>