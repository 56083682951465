<section id="whatTradiesThink">
    <div class="container-fluid">
        <div class="py-5 px-0 px-md-2">
            <h3 *ngIf="router == '/'" class="font-weight-bold text-center mb-4">We trust our tradies so can you.</h3>
            <h3 *ngIf="router == '/tradie'" class="font-weight-bold text-center mb-4">What Our Tradies Think</h3>

            <div class="col-12 carouselBlock">
                <div class="left col-md-6">
                    <a class="left_img">
                        <img src="../../../assets/images/latest_images/test-img.png" />
                    </a>
                </div>


                <div class="right col-md-6">
                    <ngx-slick-carousel class="service-slide" #slickModal="slick-carousel"
                        [config]="tradieSlidesConfig">
                        <div ngxSlickItem *ngFor="let tradie of tradiesSlides" class="mx-md-3">

                            <div class="content-block">

                                <!-- <div class="text-center my-3">
                                <span *ngIf="tradie.stars > 0">
                                    <fa-icon [icon]="faStar"></fa-icon>
                                </span>
                                <span *ngIf="tradie.stars > 1">
                                    <fa-icon [icon]="faStar"></fa-icon>
                                </span>
                                <span *ngIf="tradie.stars > 2">
                                    <fa-icon [icon]="faStar"></fa-icon>
                                </span>
                                <span *ngIf="tradie.stars > 3">
                                    <fa-icon [icon]="faStar"></fa-icon>
                                </span>
                                <span *ngIf="tradie.stars > 4">
                                    <fa-icon [icon]="faStar"></fa-icon>
                                </span>
                                <span *ngIf="tradie.halfStar == true">
                                    <fa-icon [icon]="faStarHalf"></fa-icon>
                                </span>
                            </div> -->
                                <p class="mb-4 mr-auto ml-auto content">{{tradie.content}}</p>
                                <span class="triangle"></span>
                            </div>

                            <div class="image-wrapper block mb-3">
                                <a class="tradieImg">
                                    <img class="mb-3" src="{{tradie.img}}">
                                </a>
                                <div class="text">
                                    <h5 class="font-weight-bold mb-0">{{tradie.name}}</h5>
                                    <p class="mb-0">{{tradie.address}}</p>
                                </div>

                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
    </div>
</section>