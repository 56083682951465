import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DispatcherService } from "src/app/service-dispatcher/dispatcher.service";
import { EncryptionService } from "../../encryption-service/encryption.service";
declare var window: any;

@Injectable({
  providedIn: "root"
})
export class ClientAuthService {
  baseUserUrl: string;
  envConfig = window.config;

  constructor(
    private dispatcher: DispatcherService,
    private encryptionService: EncryptionService
  ) {
    this.baseUserUrl = this.encryptionService.decryptUsingAES256(
      environment.baseUserUrl
    );
  }

  /**
   * Method: login
   * Purpose : user login
   * @param params The parameter for this method
   */
  login(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUserUrl + "login", params).subscribe(
        (res: any) => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  /**
   * Method: signUp
   * Purpose : user Registration
   * @param params The parameter for this method
   */
  signUp(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher
        .post(this.baseUserUrl + "signup", params)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  /**
   * Method: forgetPassword
   * Purpose : forget Password link share
   * @param params The parameter for this method
   */
  forgetPassword(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher
        .post(this.baseUserUrl + "forgot-password", params)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  /**
   * Method: verifyUserResetPasswordLink
   * Purpose : verify User Reset Password Link
   * @param params The parameter for this method
   */
  verifyUserResetPasswordLink(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher
        .post(this.baseUserUrl + "verify-password-link", params)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  /**
   * Method: resetUserPassword
   * Purpose : Reset User Password
   * @param params The parameter for this method
   */
  resetUserPassword(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher
        .put(this.baseUserUrl + "reset-password", params)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  /**
   * Method: verifyOtp
   * Purpose :  User verify otp
   * @param params The parameter for this method
   */
  verifyOtp(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUserUrl + "verify-otp", params).subscribe(
        (res: any) => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  /**
   * Method: verifyLoginOtp
   * Purpose : verify otp after login in two step auth
   * @param params The parameter for this method
   */
  verifyLoginOtp(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher
        .post(this.baseUserUrl + "verify-login-otp", params)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  /**
   * Method: verifyUserEmailLink
   * Purpose : client after registration verify link
   * @param params The parameter for this method
   */
  verifyUserEmailLink(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher
        .post(this.baseUserUrl + "verify-email", params)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  /**
   * Method: getStaticPages
   * Purpose : get Static Pages
   * @param params The parameter for this method
   */
  getStaticPages(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher
        .get(this.baseUserUrl + "static-page-befor-login", params)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  /**
  * Method: resendVerificationLink
  * Purpose : resend Verification Link
  * @param params The parameter for this method
  */
  resendVerificationLink(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher
        .post(this.baseUserUrl + "resend-verify-email", params)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  /**
  * Method: resendVerificationOTP
  * Purpose : resend Verification OTP
  * @param params The parameter for this method
  */
  resendVerificationOTP(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher
        .post(this.baseUserUrl + "resend-verify-otp", params)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  /**
  * Method: updateMobileNumber
  * Purpose Update Mobile Number
  * @param params The parameter for this method
  */
  updateMobileNumber(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher
        .put(this.baseUserUrl + "update-mobile-number", params)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

}
