import { CategoryInterface } from "./CategoryInterface";

/* NOTE: */
/* If you want to add another field in the object, add it first to the Interface ( categoryInterface.ts ) */
export const PopularCategories: CategoryInterface[] = [
    {
        category_name: "Builders",
        image_source: "assets/images/upload.png"
    },
    {
        category_name: "Cleaners",
        image_source: "assets/images/upload.png"
    },
    {
        category_name: "Electricians",
        image_source: "assets/images/upload.png"
    },
    {
        category_name: "Fencing",
        image_source: "assets/images/upload.png"
    },
    {
        category_name: "Handyman",
        image_source: "assets/images/upload.png"
    },
    {
        category_name: "Kitchen",
        image_source: "assets/images/upload.png"
    },
    {
        category_name: "Landscapers",
        image_source: "assets/images/upload.png"
    },
    {
        category_name: "Painters",
        image_source: "assets/images/upload.png"
    },
    {
        category_name: "Pest Control",
        image_source: "assets/images/upload.png"
    },
    {
        category_name: "Plumbers",
        image_source: "assets/images/upload.png"
    },
    {
        category_name: "Removalist",
        image_source: "assets/images/upload.png"
    },
    {
        category_name: "Roof Repair",
        image_source: "assets/images/upload.png"
    },
];