import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DispatcherService } from 'src/app/service-dispatcher/dispatcher.service';
import { EncryptionService } from '../encryption-service/encryption.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
const { detect } = require('detect-browser');
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {
  baseUrl: string;
  envConfig = window.config;

  constructor(
    private dispatcher: DispatcherService,
    private encryption: EncryptionService,
    private router: Router
  ) {
    this.baseUrl = this.encryption.decryptUsingAES256(environment.baseUrl);
  }

  /**
  * Method: createNewToken
  * Purpose: getting refreshed token from server
  */
  createNewToken() {
    const browser = detect();
    let siteAdminId = this.encryption.decryptUsingAES256(localStorage.getItem('userId'));
    let refreshToken = localStorage.getItem('refreshToken');
    let getToken = {
      "userId": siteAdminId,
      "deviceId": browser.name,
      "refreshToken": refreshToken
    }
    return this.dispatcher.post(this.baseUrl + 'access-token', getToken)
      .pipe(tap((res: any) => {
        if (res.status) {
          localStorage.setItem('accessToken', this.encryption.encryptUsingAES256(res.result.accessToken));
        }
      }, err => {
        localStorage.clear();
        this.router.navigateByUrl('');
        console.log("err", err);
      }));
  }

}
