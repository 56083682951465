import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faAngleRight, faMapMarkerAlt, faMobileAlt, faEnvelope, faCopyright, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { TermsConditionsComponent } from '../terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { CreateJobComponent } from '../create-job/create-job.component';
@Component({
  selector: 'app-footer-component',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  /** Imported FontAwesome Icons */
  faAngleRight = faAngleRight;
  faMapMarker = faMapMarkerAlt;
  faMobile = faMobileAlt;
  faEnvelope = faEnvelope;
  faFacebook = faFacebookF;
  faCopyright =faCopyright;
  faThumbsUp = faThumbsUp
  /**
  * Creates an instance of documenter.
  */
  constructor(
    private router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }
  /**
    * Method: openTermsDialog
    * Purpose : open terms dialog box
    */
  openTermsDialog(): void {
    this.dialog.open(TermsConditionsComponent, {

    });
  }

  /**
  * Method: openDialog
  * Purpose : open Privacy dialog box
  */
  openPrivacyDialog(): void {
    this.dialog.open(PrivacyPolicyComponent, {
    });
  }


  /**
  * Method: openCreateJob
  * Purpose : open Create Job dialog
  */
   openCreateJob() {
    this.dialog.open(CreateJobComponent);
  }
}
