export const messages = {
    APPOINTMENT_ACCEPTED: 'Appointment has been accepted',
    ATTACHMENT_LIMIT_EXCEED: 'Attachment limit exceeded, You can only attach upto 5 media items.',
    APPOINTMENT_CREATED: 'Appointment created',

    CATEGORY_UPDATED_SUCCESS: 'Categories updated successfulLy',
    CREATED_SUCCESS: 'Created successfully',

    FEATURE_COMING_SOON: 'Feature coming soon',
    FILE_SIZE_EXCEED: 'File size exceeded to a maximum size',
    FORGOT_PASSWORD_REQUEST: 'Your request has been submitted, check your email for a link to reset your password.',

    JOB_INVOICE_SENT: 'Job invoice sent successfully',

    MESSAGE_NOT_EMPTY: `Message can't be empty.`,
    MESSAGE_NOT_SEND: `Can't send your message`,

    
    PASSWORD_UPDATED_SUCCESS: 'Password updated successfully',
    PREFERENCE_UPDATED_SUCCESS: 'Preferences has been updated successfully',
    PROFILE_SETUP_SUCCESS: 'Profile set up successful',
    PROFILE_UPDATED: 'Profile has been updated',

    QUOTE_CREATED_SUCCESS: 'Quote has been created succesfully',
    QUOTE_TEMPLATE_CREATED_SUCCESS: 'Quote template has been created successfully',
    QUOTE_TEMPLATE_UPDATED_SUCCESS: 'Quote template has been updated successfully',
    QUOTE_UPDATED_SUCCESS: 'Quote has been updated successfully',

    REGIONS_UPDATED_SUCCESS:'Regions updated successfully',
    REPORTED_SUCCESS: 'Reported successfully',
    RESCHEDULED_SUCCESS: 'Rescheduled successfully',

    SOMETHING_WENT_WRONG: 'Something went wrong',

    THANK_YOU: 'Thank you !',
    TRADIE_NOT_WORKING_REGION: 'Tradie do not working in any region.',

    UPDATED_SUCCESS: 'Updated successfully'
};