import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { faDribbble, faFacebookF, faTwitter, faBehance, faVimeoV, faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';
import { CreateJobComponent } from '../shared/create-job/create-job.component';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  faDribbble = faDribbble;
  faFacebook = faFacebookF;
  faTwitter = faTwitter;
  faBehance = faBehance;
  faVimeo = faVimeoV;
  faGooglePlay = faGooglePlay;
  faApple = faApple;

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }
 /**
  * Method: openCreateJob
  * Purpose : open Create Job dialog
  */
  openCreateJob() {
    this.dialog.open(CreateJobComponent);
  }

}
