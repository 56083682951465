import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HttpconfigInterceptor } from './auth-interceptor';
import { LoaderComponent } from './loader/loader.component';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppConstant } from './shared/app-constant';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppInitService } from './services/app-init/app-init.service';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { DirectoryComponent } from './directory-component/directory/directory.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SearchCategoryComponent } from './directory-component/search-category/search-category.component';
import { DirectoryComponentComponent } from './directory-component/directory-component.component';
import { SearchService } from './services/search/search.service';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqComponent } from './faq/faq.component';
import { MatExpansionModule } from '@angular/material';
import { LandingComponent } from './landing/landing.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ForgotPasswordComponent } from './client/client-auth/forgot-password/forgot-password.component';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaFormsModule } from 'ng-recaptcha';

 
let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("183844326932-b94g64e1mpusk24enfor1ph901e873hc.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("975836789628067")
    // provider: new FacebookLoginProvider("1008566209620789")
  }
]);
 
export function provideConfig() {
  return config;
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "../assets/i18n/", ".json");
}

export function init_app(appLoadService: AppInitService) {
  return () => appLoadService.init();
}


@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    DirectoryComponent,
    SearchCategoryComponent,
    DirectoryComponentComponent,
    ContactUsComponent,
    MainHeaderComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    FaqComponent,
    LandingComponent,
    AboutUsComponent,
    ForgotPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    MatExpansionModule,
    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    SocialLoginModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  
  exports: [
   
  ],
 

  entryComponents: [
    ForgotPasswordComponent,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6Lfi0dcUAAAAAD9Jdr_fRS8Dj99fYnGbG6VaTFIL',
      } as RecaptchaSettings,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpconfigInterceptor,
      multi: true
    },
  
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true
    },
    AppConstant,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    SearchService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
