import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
import { environment } from 'src/environments/environment';
import { InterceptorService } from './services/interceptor-services/interceptor.service';
import { EncryptionService } from './services/encryption-service/encryption.service';

@Injectable()
export class HttpconfigInterceptor implements HttpInterceptor {
  clientSecret = environment.ClientSecret;

  constructor(
    private router: Router,
    private interceptorService: InterceptorService,
    private encryptionService: EncryptionService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('login') > 0
      || request.url.indexOf('forgot-password') > 0
      || request.url.indexOf('resetPassword') > 0
      || request.url.indexOf('verifyLink') > 0
      || request.url.indexOf('access-token') > 0
      || request.url.indexOf('reset-password') > 0
      || request.url.indexOf('verify-email') > 0
      || request.url.indexOf('signup') > 0
      || request.url.indexOf('verify-otp') > 0
      || request.url.indexOf('verify-login-otp') > 0
      || request.url.indexOf('verify-register-email') > 0
      || request.url.indexOf('verify-password-link') > 0
      || request.url.indexOf('resend-verify-otp') > 0
      || request.url.indexOf('resend-verify-email') > 0
      || request.url.indexOf('static-pages') > 0
      || request.url == 'https://api.ipify.org/?format=json') {

      if (request.url == 'https://api.ipify.org/?format=json') {
        const secretheader = request.clone({ setHeaders: { "Content-Type": "application/json" } });
        return next.handle(secretheader);
      } else {
        const secretheader = request.clone({ setHeaders: { client_secret: this.clientSecret, lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en' } });
        return next.handle(secretheader);
      }

      // const secretheader = request.clone({ setHeaders: { client_secret: this.clientSecret, lang: localStorage.getItem('lang') ? '' : 'en' } });
      // return next.handle(secretheader);

    }
    else {
      // sending incoming request by appending token to header
      return next.handle(request.clone({ setHeaders: { access_token: localStorage.getItem('accessToken') ? this.encryptionService.decryptUsingAES256(localStorage.getItem('accessToken')) : "", lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en', ip: localStorage.getItem('ip') ? localStorage.getItem('ip') : "" } }))
        .catch(error => {
          // to handle 401 unauthorized token and generating refresh token appending it to header
          // console.log(error.status);
          if (error instanceof HttpErrorResponse && error.status === 401) {

            return this.interceptorService.createNewToken().switchMap((res: any) => {
              const newreq = request.clone({ setHeaders: { access_token: res.accessToken } });
              return next.handle(newreq);
            });
          } else {
            // localStorage.clear();
            // this.router.navigateByUrl('');
            return throwError(error);
          }
        }
      );
    }

  }
}
