import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DispatcherService } from 'src/app/service-dispatcher/dispatcher.service';
import { EncryptionService } from '../../encryption-service/encryption.service';
import { CommonUtilityService } from '../../../shared/common-utility.service';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class QuotesService {

  baseJobUrl: string;
  baseUserUrl: string;
  basePaymentUrl: string;
  envConfig = window.config;
  client_id: number;


  constructor(
    private dispatcher: DispatcherService,
    private encryptionService: EncryptionService,
    private commonUtilityService: CommonUtilityService,
    private encryption: EncryptionService
  ) {
    this.baseJobUrl = this.encryptionService.decryptUsingAES256(environment.baseJobUrl);
    this.baseUserUrl = this.encryptionService.decryptUsingAES256(environment.baseUserUrl);
    this.basePaymentUrl = this.encryptionService.decryptUsingAES256(environment.basePaymentUrl);
  }

  /**
  * Method: jobQuotesRequestList
  * Purpose : Get job Quotes Request List
  * @param params The parameter for this method 
  */
  jobQuotesRequestList(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + 'opportunity', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }


   /**
  * Method: jobQuotesRequestListFilter
  * Purpose : Get job Quotes Request List with filter
  * @param params The parameter for this method 
  */
    jobQuotesRequestListFilter(params,data) {
      console.log(data , params);
      
      return new Promise((resolve, reject) => {
        this.dispatcher.post(this.baseJobUrl + 'myJobs?page='+params.page+'&itemsPerPage='+params.itemsPerPage ,data).subscribe((res: any) => {
          resolve(res);
        }, err => {
          reject(err);
        });
      });
    }



  /**
  * Method: jobQuotesReceivedList
  * Purpose : Get job Quotes Received List
  * @param params The parameter for this method 
  */
  jobQuotesReceivedList(params, clientId) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + 'opportunity/quote/' + clientId, params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: canceljobQuotesRequestDetails
  * Purpose : job Quotes Request Details cancel
  * @param params The parameter for this method 
  */
  jobQuotesRequestDetails(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + `opportunities/${params}`, '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /** 
    * Method: jobQuotesRequestDetails
    * Purpose : job Quotes Request Details
    * @param params The parameter for this method 
    */
  cancelJobRequestDetails(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.delete(this.baseJobUrl + `opportunities/${params}`, '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: getClientRegionList
  * Purpose : Get region list
  */
  getClientRegionList() {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + 'opportunity-region', '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: getClientPostalcodeList
  * Purpose : Get region list
  */
  getClientPostalcodeList() {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + 'opportunity-postcode', '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: getPostlcodeValidate
  * Purpose : Get region list
  */
  getPostlcodeValidate(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + 'opportunity-postcode-validate', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: getSuggestionAddress
  * Purpose : Get Suggestion Address list
  */
  getSuggestionAddress(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + 'opportunity-address/' + params, '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: getServiceList
  * Purpose : Get Service list
  */
  getServiceList() {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + 'opportunity-categories', '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: getJobTypeList
  * Purpose : Get job type list
  * @param params The parameter for this method 
  */
  getJobTypeList(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + `opportunity-categories/${params}/skills`, '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: getJobTimeList
  * Purpose : get Job Time List
  */
  getJobTimeList() {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + 'opportunity-time', '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: getJobTypeQuestionList
  * Purpose : Get job type question list
  * @param params The parameter for this method 
  */
  getJobTypeQuestionList(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + 'opportunity-question/' + params, '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: createJobQuote
  * Purpose : Create new Job Quote
  * @param params The parameter for this method 
  */
  createJobQuote(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseJobUrl + 'opportunities', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: cancelJobQuoteRequest
  * Purpose : cancel Job Quote Request
  * @param id The parameter for this method 
  */
  cancelJobQuoteRequest(id) {
    return new Promise((resolve, reject) => {
      this.dispatcher.delete(this.baseJobUrl + 'opportunities/' + id, '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
 * Method: cancelJobQuoteRequestMail
 * Purpose : cancel Job Quote Request mail send
 * @param id The parameter for this method 
 */
  cancelJobQuoteRequestMail(id) {
    return new Promise((resolve, reject) => {
      this.dispatcher.delete(this.baseJobUrl + 'opportunities-mail/' + id, '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: jobQuotesCancelledList
  * Purpose : Get job Quotes Cancelled List
  * @param params The parameter for this method 
  */
  jobQuotesCancelledList(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + 'client-canceled-job-requests', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: jobQuotesCompleteList
  * Purpose : Get job Quotes complete List
  * @param params The parameter for this method 
  */
  jobQuotesCompleteList(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + 'client-completed-job-requests', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method : updateJobQuote
  * Purpose : update Job Quote details
  * @param params The parameter for this method 
  */
  updateJobQuote(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.put(this.baseJobUrl + 'opportunities', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: viewRespondedTradie
  * Purpose : view responded tradie
  * @param params The parameter for this method 
  */
  viewRespondedTradie(params, opportunityId) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + `opportunities/${opportunityId}/quotes`, params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: getRatingcategories
  * Purpose : Get Rating categories
  */
  getRatingcategories() {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseUserUrl + 'rating-categories/tradie', '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: setClientRating
  * Purpose : set Client Rating
  * @param params The parameter for this method 
  */
  setClientRating(params, jobId) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUserUrl + `jobs/${jobId}/rating-reviews`, params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
   * Method: getQuoteDetails
   * Purpose : Get tradie created quote details
   * @param params The parameter for this method
   */
  getQuoteDetails(params, showAppointmentInfo = false, showTradieInfo = false) {
    return new Promise((resolve, reject) => {
      this.dispatcher
        .get(this.baseJobUrl + `quotes/${params.quoteId}`, { showAppointmentInfo: showAppointmentInfo, showTradieInfo: showTradieInfo })
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  /**
  * Method: getTradieClientRegionList
  * Purpose : Get tradie region list
  * @param params The parameter for this method 
  */
  getTradieClientRegionList(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + `tradies/${params.tradieId}/regions`, '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: getTradieClientCategoryList
  * Purpose : Get tradie category list
  * @param params The parameter for this method 
  */
  getTradieClientCategoryList(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + `/tradies/${params.tradieId}/opportunity-categories`, '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }


  /**
  * Method: getReportcategories
  * Purpose : Get Report categories
  */
  getReportcategories() {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseUserUrl + 'report-reasons', '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: createToTradieReport
  * Purpose : Create the report for tradie 
  * @param params The parameter for this method  
  */
  createToTradieReport(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUserUrl + 'report-reasons', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: isWorkCompletedByTradie
  * Purpose : Work completed by the tradie
  * @param params The parameter for this method  
  */
  isWorkCompletedByTradie(jobId, params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.put(this.baseJobUrl + `jobs/${jobId}/tradie-mark-job-completed`, params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: isWorkCompletedByTradie
  * Purpose : Work Accepted by the client
  * @param params The parameter for this method  
  */
  isAcceptCompletedWork(jobId, params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.put(this.baseJobUrl + `jobs/${jobId}/client-mark-job-completed`, params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }


  /**
  * Method: getQuoteInvoice
  * Purpose : get quote invoice 
  * @param params The parameter for this method 
  */
  getQuoteInvoice(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + 'client-get-job-quote-invoice', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: quoteAccept
  * Purpose : quote accept 
  * @param params The parameter for this method 
  */
  quoteAccept(params, quoteId) {
    return new Promise((resolve, reject) => {
      this.dispatcher.put(this.baseJobUrl + `quote/${quoteId}/accept`, params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: chatInitiated
  * Purpose : chat Initiated 
  * @param params The parameter for this method 
  */
  async chatInitiated(params) {
    return await new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUserUrl + 'chat/initiated', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: getChatCategories
  * Purpose : get Chat Categories
  * @param params The parameter for this method 
  */
  getChatCategories() {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseUserUrl + 'chat/categories', '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * Method: invoicePayment
  * Purpose : invoice Payment
  * @param params The parameter for this method 
  */
  invoicePayment(params) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.basePaymentUrl + `clients/${this.commonUtilityService.decodeToken().clientId}/charge`, params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
   * Method: fileUpload
   * Purpose : Upload File
   */
  fileUpload(uploadData) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUserUrl + `file-upload`, uploadData).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    })
  }

  /**
   * Method: fileUpload
   * Purpose : Upload File
   */
  fileDelete(deleteFileData) {
    return new Promise((resolve, reject) => {
      this.dispatcher.delete(this.baseUserUrl + `file-delete`, deleteFileData).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    })
  }



  /**
   * Method: get
   * Purpose : Popular categories 
   */

  getPopularCategories() {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseJobUrl + `opportunity-categories-popular`).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  getJobsCount(params, clientId) {
    
    return new Promise((resolve, reject) => {
      this.client_id =parseInt(this.encryption.decryptUsingAES256(localStorage.getItem('clientId')));
      this.dispatcher.post(this.baseJobUrl + `jobs/${this.client_id}/count-with-search` , params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }
}
