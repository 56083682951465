import { Component } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Tradie';
  constructor(
    private translate: TranslateService,
  ){

 const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
 translate.addLangs(['en', 'fr']);
 translate.setDefaultLang(lang ? lang : 'en');
 localStorage.setItem('lang', lang);
  }
}

