import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  encryptedData: any;

  decrypted: string;
  encrypted: string;
  constructor() { }

  encryptUsingAES256(data) {
    if (typeof (data) === 'number') {
      var name = data.toString();
    }
     else if (typeof (data) === 'boolean') {
      var name = data.toString();
    } else {
      name = data;

    }
    const _key = CryptoJS.enc.Utf8.parse(environment.AesSecretKey);
    const _iv = CryptoJS.enc.Utf8.parse(environment.AesSecretIVKey);
    const encrypted = CryptoJS.AES.encrypt((name), _key, {
      keySize: 256 / 32,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      // padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encryptedData = this.base64toHEX(this.encrypted);
    return this.encryptedData;
  }

  base64toHEX(base64) {
    const raw = atob(base64);
    let HEX = '';
    let i;
    for (i = 0; i < raw.length; i++) {
      let _hex = raw.charCodeAt(i).toString(16);
      HEX += (_hex.length === 2 ? _hex : '0' + _hex);
    }
    return HEX;
  }

  getEncrypt() {
    return this.encryptedData;
  }


  decryptUsingAES256(encrypted) {
    let _key = CryptoJS.enc.Utf8.parse(environment.AesSecretKey);
    let _iv = CryptoJS.enc.Utf8.parse(environment.AesSecretIVKey);
    let base64String = btoa(encrypted.match(/\w{2}/g).map(a => { return String.fromCharCode(parseInt(a, 16)); }).join(''))
    const decrypted = CryptoJS.AES.decrypt(
      base64String, _key, {
        keySize: 256 / 32,
        iv: _iv,
        mode: CryptoJS.mode.CBC,
        // padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }



}
