import { Component, OnInit, Injector } from '@angular/core';


@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  result: any;

  /**
  * Creates an instance of documenter.
  */
  constructor(
    // private inject: Injector,
    // private settingService: SettingService,
  ) {
    //this.getStaticPageContent();
  }

  ngOnInit() {

  }

  /**
  * Method : getStaticPageContent
  * Purpose : Get terms-conditions page content
  */
  // getStaticPageContent() {
  //   this.inject.get(LoaderService).show();
  //   this.settingService.getStaticPages({ pageType: 'terms-and-conditions' }).then((res: any) => {
  //     this.inject.get(LoaderService).hide();
  //     this.result = res
  //   }).catch(err => {
  //     this.inject.get(LoaderService).hide();
  //     console.log(err);
  //   });
  // }
}
