<!----Forgot Password-->
<div mat-dialog-content>
    <div class="popup-inner popup-xs">
        <div class="popup-header">
            <h3 class="mt-0 mb-0 pb-10 f-bold text-center text-black">Forgot Password</h3>
            <div class="forgot-pass-icon"><img src="assets/images/key.svg" alt="Forgot Password"></div>
        </div>
        <div class="popup-max-hgt">
            <div class="popup-container-s pt-20">
                <div class="f-medium text-center mb-30 fs-14">Please enter your email address below to reset your
                    password</div>
                <form [formGroup]="forgotForm">
                    <div class="form-row mb-30">
                        <label for="email">Email</label>
                        <input type="email" class="form-input" id="user-name" formControlName="email"
                            [ngClass]="{ 'has-error': submitted && f.email.errors }">
                        <div *ngIf="submitted && f.email.errors" class="error-text">
                            <span *ngIf="f.email.errors['required']"> Email is required </span>
                            <span *ngIf="f.email.errors['email']"> Enter valid email address </span>
                            <span *ngIf="f.email.errors?.maxlength">Email should be maximum 100 characters</span>
                        </div>
                    </div>
                    <div class="form-row mb-20">
                        <re-captcha formControlName="recaptchaReactive"
                            [ngClass]="{ 'has-error': submitted && f.recaptchaReactive.errors }"></re-captcha>
                        <div *ngIf="submitted && f.recaptchaReactive.errors" class="error-text">
                            <span *ngIf="f.recaptchaReactive.errors['required']">Recaptcha is required </span>
                        </div>
                    </div>
                    <div class="text-center mb-20">
                        <button type="button" (click)="onSubmit()" class="btn btn-primary btn-green">Submit</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>
<!----Forgot Password-->