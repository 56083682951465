import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { DispatcherService } from "src/app/service-dispatcher/dispatcher.service";
import { EncryptionService } from './encryption-service/encryption.service';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  baseUserUrl: string;
  envConfig = window.config;

  constructor(
    private dispatcher: DispatcherService,
    private encryptionService: EncryptionService,
    //private commonUtilityService: CommonUtilityService
  ) {
    this.baseUserUrl = this.encryptionService.decryptUsingAES256(
      environment.baseUserUrl
    );
  }


  contactUs(data) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUserUrl + `contact-tradie `, data).subscribe(
        (res: any) => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }
}
