<div mat-dialog-content class="modal" tabindex="-1" role="dialog" [ngClass]="{ 'overflow-hidden': isDropdownOpen }">
    <div class="modal-dialog modal-lg modal-dialog-centered createJob" role="document" style="margin-top: 105px;">
        <div *ngIf="!isLoading" class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">{{ modalTitle }}</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" [mat-dialog-close]="true">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="stepNumber != 7" class="text-center position-relative px-5 mx-5">
                    <ul id="progressbar" class="d-flex justify-content-center">
                        <li class="active flex-fill"><a [ngClass]="{ 'cursor-pointer' : stepNumber > 1 }"
                                (click)=" stepNumber > 1 ? backStep(1) : return()">
                                <fa-icon [icon]="faBriefcase"></fa-icon>
                            </a></li>
                        <li class="flex-fill" [ngClass]="{ 'active' : stepNumber >= 2 }"><a
                                [ngClass]="{ 'cursor-pointer' : stepNumber > 2 }"
                                (click)=" stepNumber > 2 ? backStep(2) : return()">
                                <fa-icon [icon]="faFileAlt"></fa-icon>
                            </a></li>
                        <li class="flex-fill" [ngClass]="{ 'active' : stepNumber >= 3 }"><a
                                [ngClass]="{ 'cursor-pointer' : stepNumber > 3 }"
                                (click)=" stepNumber > 3 ? backStep(3) : return()">
                                <fa-icon [icon]="faPaperclip"></fa-icon>
                            </a></li>
                        <li *ngIf="toAddDetails" class="flex-fill"
                            [ngClass]="{ 'active' : stepNumber >= 5 && toAddDetails }"><a
                                [ngClass]="{ 'cursor-pointer' : stepNumber > 5 && toAddDetails }"
                                (click)=" stepNumber > 5 ? toAdditionalQuestions() : return()">
                                <fa-icon [icon]="faQuestionCircle"></fa-icon>
                            </a></li>
                        <li class="flex-fill" [ngClass]="{ 'active' : stepNumber == 4 || stepNumber == 6 }">
                            <fa-icon [icon]="faClipboardCheck"></fa-icon>
                        </li>
                    </ul>
                </div>
                <div class="px-5">
                    <form [formGroup]="newJobForm" #formDir="ngForm">
                        <div formGroupName="job" *ngIf="stepNumber == 1">
                            <div class="row getQuote">
                                <div class="col form-group">
                                    <label for="service">What kind of service do you need?</label>
                                    <ng-multiselect-dropdown formControlName="service" class="no-placeholder"
                                        [ngClass]="{ 'is-invalid': hasError && jobControl.get('service').errors }"
                                        [placeholder]="'Select a service...'" [data]="serviceList"
                                        [settings]="serviceDropdownSettings" name="service"
                                        (click)="isDropdownOpen = true" (onSelect)="getJobTypeList()"
                                        (onDeSelect)="resetJobTypeList()" (onDropDownClose)="isDropdownOpen = false">
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="hasError && jobControl.get('service').errors"
                                        class="invalid-feedback d-block">
                                        <p class="mb-0 text-danger">Service is required</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row getQuote">
                                <div class="col form-group position-relative">
                                    <label for="service">What is the scope of the work?</label>
                                    <ng-multiselect-dropdown formControlName="type" class="no-placeholder"
                                        [ngClass]="{ 'disabled-dropdown': jobControl.get('type').disabled, 'is-invalid': hasError && jobControl.get('type').errors }"
                                        [placeholder]="'Select applicable job types...'" [data]="jobTypeList"
                                        [settings]="jobTypeDropdownSettings" name="jobType"
                                        (click)="isDropdownOpen = true" (onDropDownClose)="isDropdownOpen = false">
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="hasError && jobControl.get('type').errors"
                                        class="invalid-feedback d-block">
                                        <p class="mb-0 text-danger">Job type is required</p>
                                    </div>
                                    <div *ngIf="isLoading"
                                        class="position-absolute d-flex align-items-end justify-content-center">
                                        <p class="mb-2">Fetching Data...</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col form-group">
                                    <label for="address">Where is the job located?</label>
                                    <input type="text" #address placeholder="" formControlName="address"
                                        [ngClass]="{ 'is-invalid': hasError && jobControl.get('address').errors || !hasTradie }"
                                        class="form-control rounded-0" id="address">
                                    <div *ngIf="hasError && jobControl.get('address').errors"
                                        class="invalid-feedback d-block">
                                        <p class="mb-0 text-danger">Address is required</p>
                                    </div>
                                    <div *ngIf="!hasTradie" class="invalid-feedback d-block">
                                        <p class="mb-0 text-danger">No avaialable Tradies for this address</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div formGroupName="details" *ngIf="stepNumber == 2">
                            <div class="row">
                                <div class="col form-group">
                                    <label>Describe the work you need</label>
                                    <textarea class="form-control rounded-0" rows="6"
                                        [ngClass]="{ 'is-invalid': hasError && detailsControl.get('description').errors || detailsControl.get('description').value.length > 5000 }"
                                        formControlName="description"></textarea>
                                    <p class="mb-0 text-danger"
                                        *ngIf="detailsControl.get('description').value.length > 4999">
                                        Character limit reach: {{ detailsControl.get('description').value.length }}/5000
                                    </p>
                                    <div *ngIf="hasError && detailsControl.get('description').errors"
                                        class="invalid-feedback d-block">
                                        <p class="mb-0 text-danger">Description is required</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 form-group">
                                    <label for="appointment">When do you need it done?</label>
                                    <select class="form-control rounded-0" formControlName="appointment"
                                        [ngClass]="{ 'is-invalid': hasError && detailsControl.get('appointment').errors }"
                                        (change)="appointmentTypeChange()">
                                        <option *ngFor="let option of jobTimeList" [value]="option.opportunityTimeId">
                                            {{option.opportunityTime}}
                                        </option>
                                    </select>
                                    <div *ngIf="hasError && detailsControl.get('appointment').errors"
                                        class="invalid-feedback d-block">
                                        <p class="mb-0 text-danger">Appointment is required</p>
                                    </div>
                                </div>
                                <div *ngIf="f.details.value.appointment == 5" class="col-3 form-group">
                                    <label for="date">Date</label>
                                    <div class="input-group">
                                        <input matInput class="form-control mt-0 rounded-0" formControlName="date"
                                            [ngClass]="{ 'is-invalid': hasError && detailsControl.get('date').errors }"
                                            [matDatepicker]="picker" [min]="dateToday" (click)="picker.open()">
                                        <div class="input-group-append">
                                            <mat-datepicker-toggle class="input-group-text p-0" matSuffix
                                                [for]="picker"></mat-datepicker-toggle>
                                        </div>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </div>
                                    <div *ngIf="hasError && detailsControl.get('date').errors"
                                        class="invalid-feedback d-block">
                                        <p class="mb-0 text-danger">Date is required</p>
                                    </div>
                                </div>
                                <div *ngIf="f.details.value.appointment == 5" class="col-3 form-group">
                                    <label for="time">Time</label>
                                    <div class="input-group">
                                        <input class="form-control mt-0 rounded-0" formControlName="time"
                                            (click)="openTimePicker()"
                                            [ngClass]="{ 'is-invalid': hasError && detailsControl.get('time').errors }"
                                            #timePicker>
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-secondary px-3 py-0 rounded-0"
                                                (click)="timePicker.click()">
                                                <fa-icon [icon]="faClock"></fa-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="hasError && detailsControl.get('time').errors"
                                        class="invalid-feedback d-block">
                                        <p class="mb-0 text-danger">Time is required</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="stepNumber == 3">
                            <div class="row">
                                <div class="col">
                                    <label>Upload images and documents</label>
                                    <input class="d-none" type="file" ng2FileSelect [uploader]="uploader"
                                        accept="image/png, image/jpeg, application/pdf" multiple #fileUploader />
                                    <div class="border px-3"
                                        [ngClass]="{ 'py-5': thumb.length < 1, 'py-3': thumb.length > 0 }">
                                        <div id="filePreview" class="d-flex align-items-center py-2" *ngIf="ready">
                                            <div class="d-flex flex-column align-items-center position-relative"
                                                *ngFor="let item of thumb">
                                                <img [src]="item.url" />
                                                <div class="position-absolute text-danger bg-white rounded-circle"
                                                    (click)="onRemove(item.name)">
                                                    <fa-icon [icon]="faTimesCircle"></fa-icon>
                                                </div>
                                                <span>{{ item.name | slice:0:10 }}...</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between py-3">
                                        <div>
                                            <p class="mb-0 text-muted">
                                                <small>* Maximum of 5 files in the following formats: .jpg, .png,
                                                    .pdf</small>
                                            </p>
                                            <p class="mb-0 text-muted">
                                                <small>* Attached files allow Tradies to get good visuals about the
                                                    current state of the job</small>
                                            </p>
                                        </div>
                                        <button type="button" class="btn btn-info btn-green"
                                            (click)="fileUploader.click()">Browse...</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="jobSummary" *ngIf="stepNumber == 4 || stepNumber == 6">
                            <div class="row mb-2">
                                <div class="col">
                                    <p class="mb-0 font-weight-bold">What kind of service do you need?</p>
                                    <div class="d-flex align-items-center pl-3">
                                        <fa-icon [icon]="faCircle" class="mr-2 text-primary"></fa-icon>
                                        <p class="mb-0">
                                            {{ summary.service }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    <p class="mb-0 font-weight-bold">What is the scope of the work?</p>
                                    <div class="d-flex align-items-center pl-3">
                                        <fa-icon [icon]="faCircle" class="mr-2 text-primary"></fa-icon>
                                        <p class="mb-0">
                                            <span *ngFor="let type of summary.jobTypes; let i = index;">
                                                {{ type.skillName }}{{ summary.jobTypes.length != i + 1 ? ',' : '' }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    <p class="mb-0 font-weight-bold">Where is the job located?</p>
                                    <div class="d-flex align-items-center pl-3">
                                        <fa-icon [icon]="faCircle" class="mr-2 text-primary"></fa-icon>
                                        <p class="mb-0">
                                            {{ summary.address }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    <p class="mb-0 font-weight-bold">Describe the work you need</p>
                                    <div class="d-flex align-items-start pl-3">
                                        <fa-icon [icon]="faCircle" class="mr-2 mt-2 text-primary"></fa-icon>
                                        <p class="mb-0">
                                            {{ summaryReadMore ? summary.description : summary.description.substr(0,
                                            summaryMaxDescription) }}
                                            <span
                                                *ngIf="summary.description.length > summaryMaxDescription && !summaryReadMore">...</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p class="mb-0 text-right"
                                            *ngIf="summary.description.length > summaryMaxDescription">
                                            <small>
                                                <a class="btn btn-link p-0"
                                                    (click)="summaryReadMore = !summaryReadMore;">Read {{
                                                    summaryReadMore ? 'Less' : 'More' }}</a>
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    <p class="mb-0 font-weight-bold">When do you need it done?</p>
                                    <div class="d-flex align-items-center pl-3">
                                        <fa-icon [icon]="faCircle" class="mr-2 text-primary"></fa-icon>
                                        <p class="mb-0" [ngSwitch]="summary.appointment">
                                            <span *ngSwitchCase="1">Within 24 Hours</span>
                                            <span *ngSwitchCase="2">Next 2 Days</span>
                                            <span *ngSwitchCase="3">3 to 5 Days</span>
                                            <span *ngSwitchCase="4">Next Week</span>
                                            <span *ngSwitchCase="5"> {{ summary.jobDate | date:'mediumDate' }} - {{
                                                summary.jobTime }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div id="summaryFilePreview" class="row mb-2" *ngIf="summary.attachments.length > 0">
                                <div class="col">
                                    <p class="mb-0 font-weight-bold">Attachments</p>
                                    <span class="mx-2" *ngFor="let item of summary.attachments">
                                        <img [src]="item.url" />
                                    </span>
                                </div>
                            </div>
                            <div class="row mb-2" *ngIf="stepNumber == 6">
                                <div class="col-12" *ngFor="let item of summary.questions">
                                    <p class="mb-0 font-weight-bold">{{ item.question }}?</p>
                                    <div class="d-flex align-items-center pl-3">
                                        <fa-icon [icon]="faCircle" class="mr-2 text-primary"></fa-icon>
                                        <p class="mb-0">{{ item.answer != "" ? item.answer : "Not Specified" }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2" *ngIf="stepNumber == 6">
                                <div class="col">
                                    <p class="mb-0 font-weight-bold">How much is the budget for the job?</p>
                                    <div class="d-flex align-items-center pl-3">
                                        <fa-icon [icon]="faCircle" class="mr-2 text-primary"></fa-icon>
                                        <p class="mb-0" [ngSwitch]="summary.budget">
                                            <span *ngSwitchCase="1">Under $1000</span>
                                            <span *ngSwitchCase="2">$1000 - $5000</span>
                                            <span *ngSwitchCase="3">$5000 - $10000</span>
                                            <span *ngSwitchCase="4">More than $10000</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2" *ngIf="stepNumber == 6">
                                <div class="col">
                                    <p class="mb-0 font-weight-bold">What is the current status of the job?</p>
                                    <div class="d-flex align-items-center pl-3">
                                        <fa-icon [icon]="faCircle" class="mr-2 text-primary"></fa-icon>
                                        <p class="mb-0" [ngSwitch]="summary.status">
                                            <span *ngSwitchCase="1">Planning & Budgetting</span>
                                            <span *ngSwitchCase="2">Ready to start</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="stepNumber == 5">
                            <div *ngIf="additionalQuestionsMaxSteps > 2">
                                <div *ngFor="let question of additionalDetailsQuestionArray.controls; let i = index;"
                                    class="row">
                                    <div *ngIf="i + 1 == subStepNumber" [formGroup]="question" class="col form-group">
                                        <input type="hidden" formControlName="opportunityQuestionId">
                                        <label for="answer">{{ question.controls['question'].value }}?</label>
                                        <textarea class="form-control rounded-0" formControlName="answer" rows="6"
                                            maxlength="250">
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="subStepNumber == newJobForm.get('questions').value.length + 1" class="row">
                                <div class="col form-group">
                                    <label for="">How much is your budget for the job?</label>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" formControlName="budget" value="1">
                                        <label class="form-check-label" for="budget">
                                            Under $1000
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" formControlName="budget" value="2">
                                        <label class="form-check-label" for="budget">
                                            $1000 - $5000
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" formControlName="budget" value="3">
                                        <label class="form-check-label" for="budget">
                                            $5000 - $10000
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" formControlName="budget" value="4">
                                        <label class="form-check-label" for="budget">
                                            More than $10000
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="subStepNumber == newJobForm.get('questions').value.length + 2" class="row">
                                <div class="col form-group">
                                    <label for="">What is the current status of the job?</label>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" formControlName="jobStatus"
                                            value="1">
                                        <label class="form-check-label" for="jobStatus">
                                            Planning & Budgetting
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" formControlName="jobStatus"
                                            value="2">
                                        <label class="form-check-label" for="jobStatus">
                                            Ready to start
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="stepNumber == 7">
                            <div *ngIf="!isSuccess">
                                <div *ngIf="!isRegistration && !isLogin">
                                    <div formGroupName="userLogin">
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="email">Email Address</label>
                                                <input type="email" class="form-control rounded-0" formControlName="email"
                                                    [ngClass]="{ 'is-invalid': userLoginControl.get('email').errors }">
                                                <div *ngIf="hasError && userLoginControl.get('email').errors"
                                                    class="invalid-feedback d-block">
                                                    <p class="mb-0 text-danger">Email Address is required</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-group">
                                                <label for="password">Password</label>
                                                <input type="password" class="form-control rounded-0"
                                                    formControlName="password"
                                                    [ngClass]="{ 'is-invalid': hasError && userLoginControl.get('password').errors }">
                                                <div *ngIf="hasError && userLoginControl.get('password').errors"
                                                    class="invalid-feedback d-block">
                                                    <p *ngIf="userLoginControl.get('password').errors['required']"
                                                        class="mb-0 text-danger">Password is required</p>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="mb-0 text-right"><a class="btn btn-link p-0" (click)="openDialog()">Forgot Password</a></p>
                                        <div>
                                            <button type="submit"  (click)="onSubmit();isSignIn = true"
                                            class="btn btn-primary btn-green">Submit</button>    
                                        </div> 
                                       
                                    </div>
                                    <div id="socialLogin" class="row">
                                        <div class="col text-center">
                                            <p>Use your social media account to continue...</p>
                                            <div class="mb-3">
                                                <button
                                                    class="fb-login btn btn-info mx-auto p-0 pr-5 pl-1 d-flex align-items-center justify-content-between"
                                                    (click)="signInWithFB()">
                                                    <fa-icon [icon]="faFacebookSquare" class="mr-3 text-white">
                                                    </fa-icon> Continue with Facebook
                                                </button>
                                            </div>
                                            <div class="mb-3">
                                                <button
                                                    class="google-login btn btn-info p-0 pr-5 mx-auto d-flex align-items-center justify-content-between"
                                                    (click)="signInWithGoogle()">
                                                    <img class="mr-3"
                                                        src="assets/images/btn_google_dark_normal_ios.svg"> Continue
                                                    with Google
                                                </button>
                                            </div>
                                            <p>Don't have social media? <a class="btn btn-link p-0"
                                                    (click)="isRegistration = true">Register now</a> on our platform to
                                                continue.</p>
                                        </div>
                                    </div>
                                </div>
                                <div formGroupName="newUser" *ngIf="isRegistration && !isLogin">
                                    <div class="row">
                                        <div class="col-6 form-group">
                                            <label for="firstName">First Name</label>
                                            <input type="text" class="form-control rounded-0"
                                                formControlName="firstName"
                                                [ngClass]="{ 'is-invalid': hasError && newUserControl.get('firstName').errors }">
                                            <div *ngIf="hasError && newUserControl.get('firstName').errors"
                                                class="invalid-feedback d-block">
                                                <p class="mb-0 text-danger">First Name is required</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <label for="lastName">Last Name</label>
                                            <input type="text" class="form-control rounded-0" formControlName="lastName"
                                                [ngClass]="{ 'is-invalid': hasError && newUserControl.get('lastName').errors }">
                                            <div *ngIf="hasError && newUserControl.get('lastName').errors"
                                                class="invalid-feedback d-block">
                                                <p class="mb-0 text-danger">Last Name is required</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col form-group">
                                            <label for="email">Email Address</label>
                                            <p class="mb-0 text-muted"><small>This will be used to login on our
                                                    platform</small></p>
                                            <input type="email" class="form-control rounded-0" formControlName="email"
                                                [ngClass]="{ 'is-invalid': hasError && newUserControl.get('email').errors }">
                                            <div *ngIf="hasError && newUserControl.get('email').errors"
                                                class="invalid-feedback d-block">
                                                <p class="mb-0 text-danger">Email Address is required</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col form-group">
                                            <label for="password">Password</label>
                                            <p class="mb-0 text-muted"><small>Password must be at least 8 characters and
                                                    should be a mix of special characters,
                                                    numbers, lowercase and uppercase letters (e.g "P@ssw0rd")</small>
                                            </p>
                                            <input type="password" class="form-control rounded-0"
                                                formControlName="password"
                                                [ngClass]="{ 'is-invalid': hasError && newUserControl.get('password').errors }">
                                            <div *ngIf="hasError && newUserControl.get('password').errors"
                                                class="invalid-feedback d-block">
                                                <p *ngIf="newUserControl.get('password').errors['required']"
                                                    class="mb-0 text-danger">Password is required</p>
                                                <p *ngIf="newUserControl.get('password').errors['pattern']"
                                                    class="mb-0 text-danger">Password doesn't meet the requirements</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col form-group">
                                            <label for="confirmPassword">Confirm Password</label>
                                            <input type="password" class="form-control rounded-0"
                                                formControlName="confirmPassword"
                                                [ngClass]="{ 'is-invalid': hasError && newUserControl.get('confirmPassword').errors }">
                                            <div *ngIf="hasError && newUserControl.get('confirmPassword').errors"
                                                class="invalid-feedback d-block">
                                                <p *ngIf="newUserControl.get('confirmPassword').errors['required']"
                                                    class="mb-0 text-danger">
                                                    Confirm Password is required
                                                </p>
                                                <p *ngIf="newUserControl.get('confirmPassword').errors['mustMatch']"
                                                    class="mb-0 text-danger">
                                                    Confirm password does not match with password
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <!-- <p class="mb-0"><a class="btn btn-link p-0"
                                                    (click)="isRegistration = false; isLogin = true;">I already have an
                                                    account...</a></p> -->
                                            <p class="mb-0"><a class="btn btn-link p-0"
                                                    (click)="isRegistration = false; isLogin = false;">I'll use my
                                                    social media account...</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div formGroupName="userLogin" *ngIf="!isRegistration && isLogin">
                                    <div class="row">
                                        <div class="col form-group">
                                            <label for="email">Email Address</label>
                                            <input type="email" class="form-control rounded-0" formControlName="email"
                                                [ngClass]="{ 'is-invalid': userLoginControl.get('email').errors }">
                                            <div *ngIf="hasError && userLoginControl.get('email').errors"
                                                class="invalid-feedback d-block">
                                                <p class="mb-0 text-danger">Email Address is required</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col form-group">
                                            <label for="password">Password</label>
                                            <input type="password" class="form-control rounded-0"
                                                formControlName="password"
                                                [ngClass]="{ 'is-invalid': hasError && userLoginControl.get('password').errors }">
                                            <div *ngIf="hasError && userLoginControl.get('password').errors"
                                                class="invalid-feedback d-block">
                                                <p *ngIf="userLoginControl.get('password').errors['required']"
                                                    class="mb-0 text-danger">Password is required</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="mb-0 text-right"><a class="btn btn-link p-0">Forgot Password</a></p>
                                </div>
                            </div>
                            <div *ngIf="isSuccess">
                                <div *ngIf="isJobSaved" class="row">
                                    <div class="col text-center">
                                        <fa-icon [icon]="faCheckCircle" class="mb-2 text-primary display-3"></fa-icon>
                                        <h4 class="mb-4">{{ isRegistration ? 'Your job has been saved!' : 'Your job has
                                            been posted!' }}</h4>
                                        <p *ngIf="isRegistration" class="w-75 mx-auto">Before we can send your request
                                            to Tradies to respond to we need you to validate your contact information.
                                        </p>
                                        <ul *ngIf="isRegistration" class="w-75 py-4 pl-5 mx-auto text-left">
                                            <li>Please check your email for a verification link from us.</li>
                                            <li>Please add your phone number below.</li>
                                        </ul>
                                        <p class="w-75 mx-auto">You will receive up to maximum of 3 quotes from
                                            professional Tradies{{ isRegistration ? ' after you complete the
                                            verification process.' : '.' }}</p>
                                    </div>
                                </div>
                                <div *ngIf="!isJobSaved" class="row">
                                    <div class="col text-center">
                                        <fa-icon [icon]="faTimesCircle" class="mb-2 text-danger display-3"></fa-icon>
                                        <h4 class="mb-4">Sorry! We failed to save your job.</h4>
                                        <p *ngIf="isRegistration" class="w-75 mx-auto">You can login and try to create a
                                            new job. Please complete the following to access your dashboard:</p>
                                        <ul *ngIf="isRegistration" class="w-75 py-4 pl-5 mx-auto text-left">
                                            <li>Please check your email for a verification link from us.</li>
                                            <li>Please add your phone number below.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div *ngIf="isMobileRegistration && !isOtpValidation" class="row align-items-end">
                                    <div class="offset-1 col-8 form-group mt-5 mb-0">
                                        <label for="phoneNumber">Phone Number</label>
                                        <input type="text" class="form-control rounded-0" formControlName="phoneNumber"
                                            [ngClass]="{ 'is-invalid': hasError && f.phoneNumber.errors }">
                                        <div *ngIf="hasError && f.phoneNumber.errors" class="invalid-feedback d-block">
                                            <p *ngIf="f.phoneNumber.errors['required']" class="mb-0 text-danger">Phone
                                                Number is required</p>
                                            <p *ngIf="f.phoneNumber.errors['pattern']" class="mb-0 text-danger">Phone
                                                Number is not valid</p>
                                            <p *ngIf="f.phoneNumber.errors?.maxlength || f.phoneNumber.errors?.minlength"
                                                class="mb-0 text-danger">Phone Number must be 12 digits</p>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <button (click)="onSubmit()" class="btn btn-primary rounded-0 px-3 py-2">Send
                                            OTP</button>
                                    </div>
                                </div>
                                <div *ngIf="isOtpValidation" class="row align-items-end">
                                    <div class="offset-2 col-6 form-group mt-5 mb-0">
                                        <label for="otp">OTP</label>
                                        <input type="text" class="form-control rounded-0" formControlName="otp"
                                            [ngClass]="{ 'is-invalid': hasError && f.otp.errors }">
                                        <div *ngIf="hasError && f.otp.errors" class="invalid-feedback d-block">
                                            <p *ngIf="f.otp.errors?.required" class="mb-0 text-danger">OTP is required
                                            </p>
                                            <p *ngIf="f.otp.errors['minLength']">OTP minimum length is 4</p>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <button (click)="onSubmit()"
                                            class="btn btn-primary rounded-0 px-3 py-2">Verify</button>
                                    </div>
                                </div>
                                <div *ngIf="!isMobileRegistration && !isOtpValidation" class="row">
                                    <div class="col py-5">
                                        <h6 class="text-center text-primary">Mobile number verified! Thank you.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <div *ngIf="!isSuccess">
                    <button *ngIf="stepNumber == 4" (click)="addMoreDetails()" type="button"
                        class="btn btn-info mr-3" style="height: 40px; padding: 0 15px; border-radius: 4px;">Add More Details...</button>
                    <button *ngIf="stepNumber != 7" type="button" (click)="nextStep()"
                        class="btn btn-primary btn-green" style="width: 100px;">{{ modalButtonText }}</button>
                    <button *ngIf="isRegistration || isLogin" type="submit"  (click)="onSubmit()"
                        class="btn btn-primary btn-green">Submit</button>
                </div>
            </div>
        </div>
        <div *ngIf="isLoading" class="modal-content rounded-0">
            <div class="modal-header">
                <h6 class="modal-title">{{ modalTitle }}</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" [mat-dialog-close]="true">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="px-5">
                    <div class="row">
                        <div class="col d-flex align-items-center justify-content-center" *ngIf="!isRegistration">
                            <div class="loader mr-3"></div>
                            <p class="text-muted text-center mb-0">Loading data...</p>
                        </div>
                        <div class="col d-flex align-items-center justify-content-center" *ngIf="isRegistration">
                            <div class="loader mr-3"></div>
                            <p class="text-muted text-center mb-0">Sending registration details...</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer"></div>
        </div>
    </div>
</div>