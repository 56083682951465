<div class="container p-per-5">
    <div class="d-flex flex-row justify-content-between">
        <div class="d-flex flex-row">
            <input type="text" class="input-textbox" name="category" id="category_input" placeholder="Job Type"
                list="category_list" (change)="search(category_input.value,region_input.value)" #category_input>
            <input type="text" class="input-textbox" name="region" id="region_input" placeholder="Postcode"
                (change)="search(category_input.value,region_input.value)" value="" #region_input>
            <datalist id="category_list">
                <option *ngFor="let item of category_list" [value]="item.categoryName">{{item.categoryName}}</option>
            </datalist>
        </div>
        <div class="d-flex flex-row">
            <button class="bg-white mr-per-1">
                <img src="/assets/images/filter-icon.png" alt="">
            </button>
            <button class="bg-white">
                <img src="/assets/images/sort-icon.png" alt="">
            </button>
        </div>
    </div>

    <div *ngFor="let tradie of tradie_data" class="tradie-container">
        <div class="card">
            <div class="card-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-2 col-xs-12">
                            <div class="text-center">
                                <img src="/assets/images/choose_tradie_active.png" alt=""><br>
                                <img src="/assets/images/certified-check.png" alt="">
                                <img src="/assets/images/certified-check.png" alt="">
                                <img src="/assets/images/certified-check.png" alt="">
                            </div>
                            <div *ngIf="tradie.subscription_type == 'PREMIUM'"
                                class="premium-badge green-btn text-center">
                                Premium
                            </div>
                        </div>
                        <div class="col-sm-10 col-xs-12">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <h4 class="text-green fw-600">{{ tradie.last_name }}, {{ tradie.first_name }}</h4>
                                    <em>Tradie address will be placed here...</em>
                                    <div class="d-flex flex-row">
                                        <img src="/assets/images/star-green.png" alt="">
                                        <img src="/assets/images/star-green.png" alt="">
                                        <img src="/assets/images/star-green.png" alt="">
                                        <img src="/assets/images/star-green.png" alt="">
                                        <img src="/assets/images/star-green.png" alt="">

                                        <span class="gray-text"> / Hired 127 times on Tradie</span>
                                    </div>
                                </div>
                                <div>
                                    <button class="btn btn-primary rounded-0">REQUEST QOUTE</button>
                                </div>
                            </div>
                            <hr>
                            <div>
                                <div class="tradie-data">
                                    <label class="green-text-small" for="company_name">Company Name: </label>
                                    <p><b>{{tradie.business_name}}</b></p>

                                    <div class="d-flex flex-row">
                                        <div>
                                            <label class="green-text-small" for="email_address">Email:</label>
                                            <span class="fw-600" id="email_address"> {{tradie.email}}</span><br>

                                            <label class="green-text-small" for="phone">Phone:</label>
                                            <span class="fw-600"> {{tradie.phoneNumber}}</span>
                                        </div>
                                        <div class="pl-per-5">
                                            <label class="green-text-small" for="license">License:</label>
                                            <span class="fw-600" id="license"> {{tradie.license_number}}</span><br>

                                            <label class="green-text-small" for="">ABN Number:</label>
                                            <span class="fw-600"> {{tradie.abn_number}}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>