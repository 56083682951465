import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientAuthService } from 'src/app/services/client-services/auth/client-auth.service';
import { LoaderService } from 'src/app/loader/loader.service';
import Swal from 'sweetalert2'
import { messages } from 'src/messages';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotForm: FormGroup;
  submitted = false;

  /**
  * Creates an instance of documenter.
  */
  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private clientAuthService: ClientAuthService,
    private inject: Injector,
  ) { }

  ngOnInit() {
    this.initializationForm();
  }

  /**
  * Method : initializationForm
  * Purpose : First time Form initialization
  */
  initializationForm() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
      recaptchaReactive: ['', [Validators.required]]
    });
  }

  /**
  * Method : onNoClick
  * Purpose : Close dialog box no data update
  */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
  * get : f
  * Purpose : returns form controls value
  * @return form controls 
  */
  get f() { return this.forgotForm.controls; }

  /**
  * Method : onSubmit
  * Purpose : Send link for reset user password
  */
  onSubmit() {
    console.log('hello')

    this.submitted = true;
    if (this.forgotForm.invalid) {
      return;
    } else {
      console.log('hello 2')
      this.inject.get(LoaderService).show();
      this.clientAuthService.forgetPassword({ email: this.forgotForm.value.email, roleId: 2 }).then((res: any) => {
        console.log('hello 3' , res);
        
        this.dialogRef.close();
        this.inject.get(LoaderService).hide();
        Swal.fire(
          messages.THANK_YOU,
          messages.FORGOT_PASSWORD_REQUEST,
        )

      }).catch(err => {
        console.log(err);
        this.dialogRef.close();
        this.inject.get(LoaderService).hide();
        Swal.fire(err.error.message);
      });
    }
  }
}
