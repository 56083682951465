import { CategoryInterface } from "./CategoryInterface";


export const AllCategories: CategoryInterface[] = [

    {category_name: "Air Conditioning", image_source: ""},
    {category_name: "Antenna Services", image_source: ""},
    {category_name: "Appliance Installation", image_source: ""},
    {category_name: "Appliance Repairs", image_source: ""},
    {category_name: "Arborist", image_source: ""},
    {category_name: "Arches", image_source: ""},
    {category_name: "Attic Access Ladders", image_source: ""},
    {category_name: "Awning Suppliers", image_source: ""},
    {category_name: "Awnings", image_source: ""},
    {category_name: "Balustrading", image_source: ""},
    {category_name: "Bamboo Flooring", image_source: ""},
    {category_name: "Bath & Basin Resurfacing", image_source: ""},
    {category_name: "Bathroom Accessories", image_source: ""},
    {category_name: "Bathroom Fittings", image_source: ""},
    {category_name: "Bathroom Sinks", image_source: ""},
    {category_name: "Bathroom Vanities", image_source: ""},
    {category_name: "Baths", image_source: ""},
    {category_name: "Beds", image_source: ""},
    {category_name: "Blinds", image_source: ""},
    {category_name: "Blinds Suppliers", image_source: ""},
    {category_name: "Book Cases", image_source: ""},
    {category_name: "Brackets", image_source: ""},
    {category_name: "Bricklaying", image_source: ""},
    {category_name: "Building", image_source: ""},
    {category_name: "Building Certifiers", image_source: ""},
    {category_name: "Building Consultants", image_source: ""},
    {category_name: "Building Designer", image_source: ""},
    {category_name: "Building Supplies", image_source: ""},
    {category_name: "Building Surveyors", image_source: ""},
    {category_name: "Cabinet Doors", image_source: ""},
    {category_name: "Cabinet Hardware", image_source: ""},
    {category_name: "Cabinet Making", image_source: ""},
    {category_name: "Carpenters", image_source: ""},
    {category_name: "Carpet & Upholstery Cleaning", image_source: ""},
    {category_name: "Carpet Repair & Laying", image_source: ""},
    {category_name: "Carpet Suppliers", image_source: ""},
    {category_name: "Carpets", image_source: ""},
    {category_name: "Carports", image_source: ""},
    {category_name: "Ceilings", image_source: ""},
    {category_name: "Cement Bonding Agents", image_source: ""},
    {category_name: "Chairs", image_source: ""},
    {category_name: "Chimney Sweepers", image_source: ""},
    {category_name: "Cladding", image_source: ""},
    {category_name: "Cleaning", image_source: ""},
    {category_name: "Cleaning Products", image_source: ""},
    {category_name: "Cleaning Services - Commercial", image_source: ""},
    {category_name: "Clothesline", image_source: ""},
    {category_name: "Coating Materials", image_source: ""},
    {category_name: "Coffee Tables", image_source: ""},
    {category_name: "Columns", image_source: ""},
    {category_name: "Community Assistance", image_source: ""},
    {category_name: "Concrete Kerbs", image_source: ""},
    {category_name: "Concrete Protection Materials", image_source: ""},
    {category_name: "Concrete Resurfacing", image_source: ""},
    {category_name: "Concrete Waterproofing Materials", image_source: ""},
    {category_name: "Concreting", image_source: ""},
    {category_name: "Courses & Workshops", image_source: ""},
    {category_name: "Cubby Houses", image_source: ""},
    {category_name: "Curtains", image_source: ""},
    {category_name: "Damp Proofing", image_source: ""},
    {category_name: "Decking", image_source: ""},
    {category_name: "Decking Oil", image_source: ""},
    {category_name: "Demolition", image_source: ""},
    {category_name: "Dining Tables", image_source: ""},
    {category_name: "Door Handles", image_source: ""},
    {category_name: "Door Hardware", image_source: ""},
    {category_name: "Door Suppliers", image_source: ""},
    {category_name: "Doors", image_source: ""},
    {category_name: "Drafting", image_source: ""},
    {category_name: "Drains", image_source: ""},
    {category_name: "Drawer Systems", image_source: ""},
    {category_name: "Drawers", image_source: ""},
    {category_name: "Ducted Vacuum Systems", image_source: ""},
    {category_name: "Electricians", image_source: ""},
    {category_name: "Engineering - Structural", image_source: ""},
    {category_name: "Entertainment Units", image_source: ""},
    {category_name: "Equipment Hire", image_source: ""},
    {category_name: "Excavation", image_source: ""},
    {category_name: "Extensions & Additions", image_source: ""},
    {category_name: "Fencing", image_source: ""},
    {category_name: "Fencing Materials", image_source: ""},
    {category_name: "Fencing Suppliers", image_source: ""},
    {category_name: "Feng Shui", image_source: ""},
    {category_name: "Financial Planning", image_source: ""},
    {category_name: "Fire Protection Materials", image_source: ""},
    {category_name: "Fireplaces", image_source: ""},
    {category_name: "Floor Coating Products", image_source: ""},
    {category_name: "Floor Coatings", image_source: ""},
    {category_name: "Floor Sanding & Polishing", image_source: ""},
    {category_name: "Flyscreens", image_source: ""},
    {category_name: "Frames & Trusses", image_source: ""},
    {category_name: "Furniture - Custom Design", image_source: ""},
    {category_name: "Furniture - Outdoor", image_source: ""},
    {category_name: "Furniture - Retailers", image_source: ""},
    {category_name: "Furniture - Second Hand", image_source: ""},
    {category_name: "Furniture Removal", image_source: ""},
    {category_name: "Garages", image_source: ""},
    {category_name: "Garden Art", image_source: ""},
    {category_name: "Garden Designer", image_source: ""},
    {category_name: "Garden Features", image_source: ""},
    {category_name: "Garden Maintenance", image_source: ""},
    {category_name: "Garden Ornaments", image_source: ""},
    {category_name: "Garden Sculpture", image_source: ""},
    {category_name: "Garden Statues", image_source: ""},
    {category_name: "Garden Supplies", image_source: ""},
    {category_name: "Gas Fitters", image_source: ""},
    {category_name: "Gates", image_source: ""},
    {category_name: "Gazebo", image_source: ""},
    {category_name: "Glass & Glazing", image_source: ""},
    {category_name: "Glass Balustrades", image_source: ""},
    {category_name: "Glass Suppliers", image_source: ""},
    {category_name: "Graffiti Protection", image_source: ""},
    {category_name: "Grates", image_source: ""},
    {category_name: "Gutter Cleaning", image_source: ""},
    {category_name: "Gutter Protection", image_source: ""},
    {category_name: "Gutter Suppliers", image_source: ""},
    {category_name: "Guttering", image_source: ""},
    {category_name: "Handles", image_source: ""},
    {category_name: "Handrail Suppliers", image_source: ""},
    {category_name: "Handrails", image_source: ""},
    {category_name: "Handyman", image_source: ""},
    {category_name: "Hardware", image_source: ""},
    {category_name: "Heaters", image_source: ""},
    {category_name: "Heating Systems", image_source: ""},
    {category_name: "Home Automation", image_source: ""},
    {category_name: "Home Security Products", image_source: ""},
    {category_name: "Home Theatre", image_source: ""},
    {category_name: "Homewares", image_source: ""},
    {category_name: "Hot Water Systems", image_source: ""},
    {category_name: "IKEA Bathrooms", image_source: ""},
    {category_name: "IKEA Kitchen Installers", image_source: ""},
    {category_name: "IKEA Lighting Installation and Assembly", image_source: ""},
    {category_name: "Insect Screen Suppliers", image_source: ""},
    {category_name: "Inspections - Building", image_source: ""},
    {category_name: "Inspections - Pest", image_source: ""},
    {category_name: "Insulation", image_source: ""},
    {category_name: "Interior Decorating", image_source: ""},
    {category_name: "Interior Designer", image_source: ""},
    {category_name: "Irrigation Systems", image_source: ""},
    {category_name: "Joinery", image_source: ""},
    {category_name: "Kit Homes", image_source: ""},
    {category_name: "Kitchen", image_source: ""},
    {category_name: "Kitchen Appliances", image_source: ""},
    {category_name: "Kitchen Design", image_source: ""},
    {category_name: "Kitchen Fittings", image_source: ""},
    {category_name: "Kitchen Sinks", image_source: ""},
    {category_name: "Landscape Architecture", image_source: ""},
    {category_name: "Landscaping & Gardening", image_source: ""},
    {category_name: "Lawn & Turf", image_source: ""},
    {category_name: "Lawn Mowing", image_source: ""},
    {category_name: "Lifts", image_source: ""},
    {category_name: "Lighting", image_source: ""},
    {category_name: "Lighting Suppliers", image_source: ""},
    {category_name: "Limestone", image_source: ""},
    {category_name: "Locks", image_source: ""},
    {category_name: "Locksmiths", image_source: ""},
    {category_name: "Louvre Roofs", image_source: ""},
    {category_name: "Mailboxes", image_source: ""},
    {category_name: "Marble Stone", image_source: ""},
    {category_name: "Mirrors", image_source: ""},
    {category_name: "Mortgage Brokers", image_source: ""},
    {category_name: "Mouldings", image_source: ""},
    {category_name: "Natural Stone", image_source: ""},
    {category_name: "Nurseries", image_source: ""},
    {category_name: "Paint Suppliers", image_source: ""},
    {category_name: "Painters", image_source: ""},
    {category_name: "Panel Suppliers", image_source: ""},
    {category_name: "Patios", image_source: ""},
    {category_name: "Paving", image_source: ""},
    {category_name: "Paving Supply", image_source: ""},
    {category_name: "Pergolas", image_source: ""},
    {category_name: "Pest Control", image_source: ""},
    {category_name: "Pest Control Products", image_source: ""},
    {category_name: "Pipes", image_source: ""},
    {category_name: "Plastering & Gyprock", image_source: ""},
    {category_name: "Playground Equipment", image_source: ""},
    {category_name: "Plumbers", image_source: ""},
    {category_name: "Plunge Pools", image_source: ""},
    {category_name: "Polishes", image_source: ""},
    {category_name: "Pool Accessories", image_source: ""},
    {category_name: "Pool Builders", image_source: ""},
    {category_name: "Pool Covers", image_source: ""},
    {category_name: "Pool Fencing", image_source: ""},
    {category_name: "Pool Heating", image_source: ""},
    {category_name: "Pool Maintenance", image_source: ""},
    {category_name: "Pool Suppliers", image_source: ""},
    {category_name: "Pots", image_source: ""},
    {category_name: "Pressure Cleaning", image_source: ""},
    {category_name: "Primer", image_source: ""},
    {category_name: "Privacy Screens", image_source: ""},
    {category_name: "Professional Organisers", image_source: ""},
    {category_name: "Project Management", image_source: ""},
    {category_name: "Rainwater Tanks", image_source: ""},
    {category_name: "Render", image_source: ""},
    {category_name: "Rendering", image_source: ""},
    {category_name: "Reproduction Stone", image_source: ""},
    {category_name: "Retaining Wall Suppliers", image_source: ""},
    {category_name: "Retaining Walls", image_source: ""},
    {category_name: "Retractable Screen Suppliers", image_source: ""},
    {category_name: "Roller Doors", image_source: ""},
    {category_name: "Roller Shutters", image_source: ""},
    {category_name: "Roof Materials", image_source: ""},
    {category_name: "Roof Repairs", image_source: ""},
    {category_name: "Roofing", image_source: ""},
    {category_name: "Rubbish Removal", image_source: ""},
    {category_name: "Rugs", image_source: ""},
    {category_name: "Safety Equipment", image_source: ""},
    {category_name: "Saunas", image_source: ""},
    {category_name: "Scaffolding", image_source: ""},
    {category_name: "Screen Enclosures", image_source: ""},
    {category_name: "Sealants", image_source: ""},
    {category_name: "Security", image_source: ""},
    {category_name: "Security Screens & Doors", image_source: ""},
    {category_name: "Shades & Sails", image_source: ""},
    {category_name: "Sheds", image_source: ""},
    {category_name: "Shelves", image_source: ""},
    {category_name: "Shopfitters", image_source: ""},
    {category_name: "Shower Repairs", image_source: ""},
    {category_name: "Shower Screens", image_source: ""},
    {category_name: "Skip & Truck Hire", image_source: ""},
    {category_name: "Skylights", image_source: ""},
    {category_name: "Solar Power", image_source: ""},
    {category_name: "Solar Power Accessories", image_source: ""},
    {category_name: "Spas", image_source: ""},
    {category_name: "Splashback Suppliers", image_source: ""},
    {category_name: "Splashbacks", image_source: ""},
    {category_name: "Stained Glass", image_source: ""},
    {category_name: "Staircases", image_source: ""},
    {category_name: "Stone Veneer", image_source: ""},
    {category_name: "Stonemasonry", image_source: ""},
    {category_name: "Storage", image_source: ""},
    {category_name: "Surveyors", image_source: ""},
    {category_name: "Termite Control Products", image_source: ""},
    {category_name: "Tile Suppliers", image_source: ""},
    {category_name: "Tilers", image_source: ""},
    {category_name: "Timber Flooring", image_source: ""},
    {category_name: "Toilet Suppliers", image_source: ""},
    {category_name: "Tools", image_source: ""},
    {category_name: "Town Planning", image_source: ""},
    {category_name: "Tree Felling", image_source: ""},
    {category_name: "TV Units", image_source: ""},
    {category_name: "Underfloor Heating", image_source: ""},
    {category_name: "Underpinning", image_source: ""},
    {category_name: "Upholstery Repair", image_source: ""},
    {category_name: "Vacuum Cleaners", image_source: ""},
    {category_name: "Ventilation", image_source: ""},
    {category_name: "Ventilation Products", image_source: ""},
    {category_name: "Verandahs", image_source: ""},
    {category_name: "Vinyl & Laminate", image_source: ""},
    {category_name: "Wallpapering", image_source: ""},
    {category_name: "Wardrobe Suppliers", image_source: ""},
    {category_name: "Wardrobes", image_source: ""},
    {category_name: "Water Conservation Products", image_source: ""},
    {category_name: "Water Features", image_source: ""},
    {category_name: "Water Filtration Systems", image_source: ""},
    {category_name: "Water Pumps", image_source: ""},
    {category_name: "Waterproofing", image_source: ""},
    {category_name: "Waterproofing Products", image_source: ""},
    {category_name: "Window Cleaning", image_source: ""},
    {category_name: "Window Repairs", image_source: ""},
    {category_name: "Window Shutters", image_source: ""},
    {category_name: "Window Tinting", image_source: ""},
    {category_name: "Windows", image_source: ""},
    {category_name: "Wine Racks", image_source: ""},
    {category_name: "Wood Care Products", image_source: ""},
];