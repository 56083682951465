import { Component, OnInit, Injector } from '@angular/core';


@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  // result: any;

  /**
  * Creates an instance of documenter.
  */
  constructor(
    // private inject: Injector,
    // private settingService: SettingService,
  ) {
    // this.getStaticPageContent();
  }

  ngOnInit() {

  }

  /**
  * Method : getStaticPageContent
  * Purpose : Get terms-conditions page content
  */
  // getStaticPageContent() {
  //   this.inject.get(LoaderService).show();
  //   this.settingService.getStaticPages({ pageType: 'privacy-policy' }).then((res: any) => {
  //     this.inject.get(LoaderService).hide();
  //     this.result = res;
  //   }).catch(err => {
  //     this.inject.get(LoaderService).hide();
  //     console.log(err);
  //   });
  // }
}
